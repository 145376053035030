<div id="component-content">
    <div class="container-fluid mb-4">
        <div class="row">
            <div class="col-md d-flex flex-column">
                <div class="drop-area">
                    <b>Drop here images, videos or gifs</b>
                </div>
                <button mat-flat-button color="primary" (click)="addWithoutMedia()" class="mt-3 w-100 w-xl-auto">
                    <mat-icon class="me-2">add</mat-icon>Add without media
                </button>
            </div>
            <div class="col-md-8">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title><mat-icon class="me-2">settings</mat-icon>
                            <div>Brand Settings</div>
                        </mat-card-title>
                        <mat-card-subtitle>Use these settings for the content generation</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="container">
                            <div class="row">
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Text Character</mat-label>
                                        <mat-select [(value)]="settingsCharacter">
                                            <mat-option [value]="null">Not specified</mat-option>
                                            <mat-option *ngFor="let c of characters; index as i"
                                                [value]="i">{{c}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Perspective</mat-label>
                                        <mat-select [(value)]="settingsPerspective">
                                            <mat-option [value]="null">Not specified</mat-option>
                                            <mat-option *ngFor="let p of perspectives; index as i"
                                                [value]="i">{{p}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Language</mat-label>
                                        <mat-select [(value)]="settingsLanguage">
                                            <mat-option *ngFor="let l of languages; index as i"
                                                [value]="i">{{l}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                    <mat-card-actions class="d-flex">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl">
                                    <button [routerLink]="['/management/clients/', this.client?.id]" mat-flat-button
                                        color="accent" class="me-2 w-100 w-xl-auto"><mat-icon
                                            class="me-2">edit</mat-icon>Go
                                        to edit
                                        client</button>
                                </div>
                                <div class="col-xl d-flex justify-content-start justify-content-xl-end">
                                    <button mat-flat-button color="primary" class="mt-2 mt-xl-0 w-100 w-xl-auto"
                                        (click)="saveClientSettings()">
                                        <mat-icon class="me-2">save</mat-icon>Save client settings
                                    </button>
                                </div>
                            </div>
                        </div>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>
    <div class="mt-4" *ngFor="let item of items; index as i">
        <app-copy-generation-item [language]="settingsLanguage" [character]="settingsCharacter"
            [perspective]="settingsPerspective" [image]="item.url" [proposals]="item.proposals" [name]="item.name"
            [guid]="item.guid" (onRemove)="removeItem(i)">
        </app-copy-generation-item>
    </div>
</div>