import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, OnInit} from '@angular/core';
//import {Component, ElementRef, ViewChild, inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {LiveAnnouncer} from '@angular/cdk/a11y';
//import {Post, PostTarget, PostTargetType, PostProject, Tag} from 'src/app/models/models';
//import {PostsService} from 'src/app/services/posts.service';
import {Client} from "../../../models/client-management";
import {ClientsService} from "../../../services/clients.service";
import {Event} from "@angular/router";

@Component({
  selector: 'app-dashboard',
  templateUrl: './post-manager.component.html',
  styleUrls: ['./post-manager.component.scss']
})
export class PostManagerComponent implements OnInit {
  search($event: Event) {
    throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
  }


/*
  public opened: boolean = true;
  public showDrafts: boolean = true;
  public showPublished: boolean = false;
  public showScheduled: boolean = false;
  public announcer = inject(LiveAnnouncer);

  public client: Client | null = null;

  public separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit(): void {
  }

  setClient(client: Client) {
    console.log("Selecting client " + client.name);
    this.client = client;

    //this.projects = this.postsService.getProjects(client.id);
  }

  constructor(private postsService: PostsService, private clientsService: ClientsService) {
    this.clientsService.current$.subscribe(client => {
      this.setClient(client);
    });
    if (this.clientsService.current !== null)
      this.setClient(this.clientsService.current);
  }


  public tagCtrl = new FormControl('');
  public tags: Tag[] = [];
  public alltags: Tag[] = [
    {id: 0, name: 'Christmas'},
    {id: 1, name: 'San Valentino'},
    {id: 2, name: 'The witch'},
    {id: 3, name: '1° semester 2024'},
    {id: 4, name: '2° semester 2024'}
  ];

  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement> | undefined;

  removeTag(tag: Tag): void {
    const index = this.tags.indexOf(tag);

    if (index >= 0) {
      this.tags.splice(index, 1);
      this.announcer.announce(`Removed ${tag}`);
      this.alltags.push(tag);
      this.alltags.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  tagSelected(event: MatAutocompleteSelectedEvent): void {

    let tag = this.alltags.find(t => t.id === event.option.value);

    if (tag === undefined) {
      return;
    }

    this.tags.push(tag);
    this.tagInput!.nativeElement.value = '';
    this.tagCtrl.setValue(null);
    this.alltags.splice(this.alltags.indexOf(tag), 1);
    this.announcer.announce(`Added ${event.option.viewValue}`);
  }


  public targetCtrl = new FormControl('');
  public targets: PostTarget[] = [];
  public alltargets: PostTarget[] = [
    {id: 0, name: 'Luca Di Leo', type: PostTargetType.FacebookProfile},
    {id: 1, name: 'Notizie del giorno', type: PostTargetType.FacebookPage},
    {id: 2, name: 'Amici della strada', type: PostTargetType.FacebookGroup},
    {id: 3, name: 'Vacanze da sogno', type: PostTargetType.InstagramProfile},
    {id: 4, name: 'Digipa S.R.L.', type: PostTargetType.LinkedInPage}
  ];
  @ViewChild('targetInput') targetInput: ElementRef<HTMLInputElement> | undefined;

  removeTarget(target: PostTarget): void {

    const index = this.targets.indexOf(target);

    if (index >= 0) {
      this.targets.splice(index, 1);
      this.announcer.announce(`Removed ${target}`);
      this.alltargets.push(target);
      this.alltargets.sort((a, b) => a.name.localeCompare(b.name));
    }
  }

  targetSelected(event: MatAutocompleteSelectedEvent): void {

    let target = this.alltargets.find(t => t.id === event.option.value);

    if (target === undefined) {
      return;
    }

    this.targets.push(target);
    this.targetInput!.nativeElement.value = '';
    this.targetCtrl.setValue(null);
    this.alltargets.splice(this.alltargets.indexOf(target), 1);
    this.announcer.announce(`Added ${event.option.viewValue}`);

  }

*/
}
