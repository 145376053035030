import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Subject} from "rxjs";
import {Injectable} from "@angular/core";
import {User} from "../models/users";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  private baseUrl: string = environment.baseUrl;

  private User: User | null = null;

  constructor(private http: HttpClient) {
  }

  async self(forceRefresh: boolean = false): Promise<User> {

    if (this.User !== null && !forceRefresh) {
      return this.User;
    }

    return new Promise<User>((resolve, reject) => {
      this.http.get<User>(this.baseUrl + 'users/self').subscribe({
        next: (result) => {
          this.User = result;
          resolve(result);
        },
        error: e => reject(e)
      });
    });
  }

}
