<h1 mat-dialog-title>{{ data.title }}</h1>

<form #inputForm="ngForm" (ngSubmit)="onConfirm(inputForm)" novalidate>
    <div mat-dialog-content class="mat-typography">
        <mat-form-field class="w-100">
            <mat-label>{{ data.message }}</mat-label>
            <input matInput [(ngModel)]="data.inputValue" name="inputValue" placeholder="{{data.inputPlaceholder}}*"
                   [type]="data.inputType" required #inputCtrl="ngModel">
            @if (inputCtrl.value && inputCtrl.value.length > 0) {
                <button matSuffix mat-icon-button aria-label="Clear" (click)="data.inputValue = ''">
                    <mat-icon>close</mat-icon>
                </button>
            }
            @if (inputCtrl.invalid && !inputCtrl.hasError('required')) {
                <mat-error>This field is required.</mat-error>
            }
        </mat-form-field>
    </div>

    <div mat-dialog-actions align="end">
        <button mat-flat-button [color]="data.cancelColor" (click)="onCancel()">{{ data.cancelText }}</button>
        <button mat-flat-button cdkFocusInitial [color]="data.confirmColor"
                [disabled]="inputForm.invalid"> {{ data.confirmText }}
        </button>
    </div>
</form>
