<p>dashboard works!</p>

<p *ngIf="!forecasts?.length"><em>Loading...</em></p>

<table class='table table-striped' aria-labelledby="tableLabel" *ngIf="forecasts.length">
  <thead>
  <tr>
    <th>Date</th>
    <th>Temp. (C)</th>
    <th>Temp. (F)</th>
    <th>Summary</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let forecast of forecasts">
    <td>{{ forecast.date }}</td>
    <td>{{ forecast.temperatureC }}</td>
    <td>{{ forecast.temperatureF }}</td>
    <td>{{ forecast.summary }}</td>
  </tr>
  </tbody>
</table>
