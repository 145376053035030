import {Inject, Injectable} from '@angular/core';
import {Character, Client, ClientCreate, Language, Perspective} from "../models/client-management";

import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {firstValueFrom, Subject} from "rxjs";
import {DeletedMediaResult, UploadedMedia} from "../models/media";

@Injectable({
    providedIn: 'root'
})
export class ClientsService {

    private baseUrl: string = environment.baseUrl;

    private cachedClients: Client[] | null = null;

    constructor(private http: HttpClient) {
    }

    public refreshSource = new Subject<boolean>();

    refresh() {
        this.refreshSource.next(true);
    }

    async getAll(): Promise<Client[]> {
        try {
            const url = this.baseUrl + 'clients';
            let res = await firstValueFrom(this.http.get<Client[]>(url));

            this.cachedClients = res;

            return res;

        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async get(id: number): Promise<Client | null> {
        try {

            if (this.cachedClients) {
                let client = this.cachedClients.find(c => c.id == id);
                if (client) {
                    return client;
                }
            }

            const url = this.baseUrl + 'clients/' + id;
            return firstValueFrom(this.http.get<Client>(url));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async add(client: ClientCreate) {
        try {
            const url = this.baseUrl + 'clients';
            let res = await firstValueFrom(this.http.post<Client>(url, client));

            if (this.cachedClients) {
                this.cachedClients.push(res);
            }

            return res;

        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async edit(client: Client) {
        try {
            const url = this.baseUrl + 'clients/' + client.id;
            let res = firstValueFrom(this.http.put<Client>(url, client));

            if (this.cachedClients) {
                let index = this.cachedClients.findIndex(c => c.id == client.id);
                if (index != -1) {
                    this.cachedClients[index] = client;
                }
            }

            return res;

        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async remove(id: number, directRemoval: boolean = false) {
        try {
            const url = this.baseUrl + 'clients/' + id + '?directRemoval=' + directRemoval;
            let res = firstValueFrom(this.http.delete<void>(url));

            if (this.cachedClients) {
                this.cachedClients = this.cachedClients.filter(c => c.id != id);
            }

            return res;

        } catch (e) {
            console.error(e);
            throw e;
        }
    }


    async addMedia(id: number, url: string) : Promise<UploadedMedia> {

        const data = {url: url};

        try {
            const url = this.baseUrl + 'clients/' + id + '/add-media';
            return firstValueFrom(this.http.post<UploadedMedia>(url, data));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
