<h1 mat-dialog-title>Upload media</h1>

<div mat-dialog-content class="mat-typography">
    <angular-file-uploader [resetUpload]=resetUpload [config]="afuConfig" class="afuc"
                           (ApiResponse)="docUpload($event)" (fileSelected)="onFileSelected($event)">
    </angular-file-uploader>
    <mat-card *ngFor="let file of items" class="mb-3">

        <mat-card-header style="overflow-x: hidden">
            <div mat-card-avatar *ngIf="file.status == 0">
                <mat-spinner diameter="50"></mat-spinner>
            </div>
            <div class="status-indicator" *ngIf="file.status == 1 && file.type == 0" mat-card-avatar
                 [ngStyle]="{'background-image': 'url('+file.url+')'}">
            </div>
            <div class="status-indicator d-flex justify-content-center align-items-center" *ngIf="file.status == 1 && file.type == 1" mat-card-avatar>
                 <mat-icon style="font-size: xxx-large; width: 50px; height: 50px;">movie</mat-icon>
            </div>
            <div mat-card-avatar class="d-flex justify-content-center align-items-center" *ngIf="file.status == 2">
                <mat-icon style="font-size: xxx-large; width: 50px; height: 50px;">error</mat-icon>
            </div>
            <mat-card-title>{{ file.name }}</mat-card-title>
        </mat-card-header>
        <!--<img mat-card-image src="{{file.url}}" class="img-flud">-->

    </mat-card>
</div>

<div mat-dialog-actions align="end">
    <button mat-flat-button color="primary" (click)="onCancel()">Close</button>
</div>
