import {Component, OnDestroy, OnInit} from '@angular/core';
import {
    Character,
    CHARACTERS, Client, ClientCreate,
    Language,
    LANGUAGES,
    Perspective,
    PERSPECTIVES, Project, ProjectCreate,
    ViewClient, ViewProject
} from "../../../models/client-management";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ClientsService} from "../../../services/clients.service";
import {ProjectsService} from "../../../services/projects.service";
import {deepClone, equals, getClientFromRoute, openSnackBar} from "../../../utils";
import {ActivatedRoute, Router} from "@angular/router";
import {firstValueFrom, Subject, takeUntil} from "rxjs";
import {EditClientDialogComponent} from "../../../dialogs/edit-client-dialog/edit-client-dialog.component";
import {DialogActionCancel, DialogActionDelete, DialogResult} from "../../../models/models";
import {ConfirmDialogComponent, DialogData} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {EditProjectDialogComponent} from "../../../dialogs/edit-project-dialog/edit-project-dialog.component";

@Component({
    selector: 'app-projects-management',
    templateUrl: './projects-management.component.html',
    styleUrl: './projects-management.component.scss'
})
export class ProjectsManagementComponent implements OnInit, OnDestroy {
    public languages: string[] = LANGUAGES;
    public characters: string[] = CHARACTERS;
    public perspectives: string[] = PERSPECTIVES;

    public projects: ViewProject[] = [];

    private client: Client | null = null;
    private destroy$ = new Subject<void>();

    public isBusy: boolean = false;

    constructor(public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private projectsService: ProjectsService,
                private route: ActivatedRoute,
                private router: Router,
                private clientsService: ClientsService) {

        // Monitor route changes
        this.route.params.subscribe(params => {
            let clientId = params['clientId'];
            console.log("Client from route subscribe: ", clientId);
            if (clientId) {
                this.clientsService.get(+clientId).then(client => {
                    this.client = client;
                    this.refreshProjects();
                });
            }
        });
    }

    ngOnInit(): void {

        /*
        getClientFromRoute(this.route, this.clientsService).then(client => {
            if (client) {
                this.clientId = client.id;
                this.refreshProjects();
            } else {
                this.router.navigate(['/management/clients']);
                return;
            }
        });*/

        /*this.clientsService.current$.pipe(takeUntil(this.destroy$)).subscribe(client => {

            console.log("Client: ", client);

            if (client) {
                this.clientId = client.id;
                this.refreshProjects();
            } else {
                this.router.navigate(['/management/clients']);
                return;
            }
        });

        // Access the projectId parameter from the URL
        this.route.paramMap.pipe(takeUntil(this.destroy$)).subscribe(params => {
            const id = params.get('clientId');
            if (id && !isNaN(Number(id))) {
                console.log("Client id found in route, using it: ", id);
                this.clientId = Number(id);
                this.refreshProjects();
            } else {
                console.log("No client id found in route, using current client: ", this.clientsService.current);
                this.clientId = this.clientsService.current?.id ?? 0;
                this.refreshProjects();
            }
        });
    }*/
    }

    ngOnDestroy(): void {
        this.destroy$.next(); // Emit a value to signal subscriptions to complete
        this.destroy$.complete(); // Complete the Subject
    }


    private refreshProjects(): void {
        this.isBusy = true;

        const id = this.client?.id;

        if (!id) {
            console.error("No client id found");
            this.isBusy = false;
            return;
        }

        this.projectsService.getAll(id).then(projects => {
            this.projects = projects.map(p => {
                return {
                    id: p.id,
                    name: p.name,
                    description: p.description,
                    language: p.language,
                    character: p.character,
                    perspective: p.perspective,
                    emoticons: p.emoticons
                }
            });

            this.isBusy = false;

        }, e => {
            console.error(e);
            this.isBusy = false;

            openSnackBar(this.snackBar, "Error loading projects", "Retry", 3000, () => {
                this.ngOnInit();
            });

        });
    }

    public async addProject() {

        const dialogRef = this.dialog.open(EditProjectDialogComponent, {
            width: '40rem',
            data: {project: null, client: this.client}
        });

        let dialogResult: DialogResult<Project> = await firstValueFrom(dialogRef.afterClosed());

        if (dialogResult.action == DialogActionCancel)
            return;

        const result = dialogResult.data;

        if (result == null) {
            console.log("No data entered");
            return;
        }

        console.log("Creating project");
        try {

            this.isBusy = true;

            let addUser: ProjectCreate = result as ProjectCreate;

            addUser.clientId = this.client?.id!;

            const client = await this.projectsService.add(addUser);

            this.projects.push({
                id: client.id,
                name: client.name,
                description: client.description,
                language: client.language,
                character: client.character,
                perspective: client.perspective,
                emoticons: client.emoticons
            });

            openSnackBar(this.snackBar, "Project created", "close");

            this.isBusy = false;

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error creating project", "Retry", 3000, () => {
                this.addProject();
            });
        }

    }

    public async editProject(project: ViewProject) {
        const dialogRef = this.dialog.open(EditProjectDialogComponent, {
            width: '40rem',
            data: {client: this.client, project: deepClone(project)}
        });

        let dialogResult: DialogResult<Project> = await firstValueFrom(dialogRef.afterClosed());

        if (!dialogResult || dialogResult.action == DialogActionCancel)
            return;

        if (dialogResult.action == DialogActionDelete) {
            await this.deleteProject(project.id);
            return;
        }

        const result = dialogResult.data;

        // Check if no changes were made
        if (result == null || equals(project, result)) {
            console.log("No changes were made");
            return;
        }

        console.log("Updating project");
        try {

            this.isBusy = true;

            await this.projectsService.edit({
                id: project.id,
                name: result.name,
                description: result.description,
                language: result.language,
                character: result.character,
                perspective: result.perspective,
                emoticons: result.emoticons
            });

            openSnackBar(this.snackBar, "Project updated", "close");

            project.name = result.name;
            project.description = result.description;
            project.language = result.language;
            project.character = result.character;
            project.perspective = result.perspective;
            project.emoticons = result.emoticons;

            this.isBusy = false;

            // broadcast changes to other components
            this.clientsService.refresh();

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error updating project", "Retry", 3000, () => {
                this.editProject(project);
            });
        }
    }

    async deleteProject(projectId: number) {
        console.log("Delete project with id: " + projectId);

        const dialogData: DialogData = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this project?',
            confirmText: 'Yes',
            cancelText: 'No',
            cancelColor: 'primary',
            confirmColor: 'warn'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: dialogData
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        console.log("Deleting project");

        this.isBusy = true;

        try {

            // TODO: Per ora teniamo in standby la feature del cestino
            await this.projectsService.remove(projectId, true);

            this.projects = this.projects.filter(p => p.id != projectId);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Project deleted", "close");

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error deleting project", "Retry", 3000, () => {
                this.deleteProject(projectId);
            });
        }

    }

}
