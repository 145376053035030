export interface ViewClient {
  id: number;
  name: string;
  description: string | null;
  website: string | null;
  language: Language;
  character: Character | null;
  perspective: Perspective | null;
  emoticons: boolean | null;
}

export interface EditProjectDialogData {
    project: ViewProject | null;
    client: Client;
}

export interface ViewProject {
  id: number;
  name: string;
  description: string | null;
  language: Language;
  character: Character | null;
  perspective: Perspective | null;
  emoticons: boolean | null;
}

export interface Client {
  id: number;
  name: string;
  description: string | null;
  language: Language;
  character: Character | null;
  perspective: Perspective | null;
  website: string | null;
  emoticons: boolean | null;
}
export interface Project {
  id: number;
  name: string;
  description: string | null;
  language: Language;
  character: Character | null;
  perspective: Perspective | null;
  emoticons: boolean | null;
}
export interface ClientCreate extends Client {
  userId: number;
}
export interface ProjectCreate extends Client {
  clientId: number;
}
export enum Language {
  Italian = 0,
  English = 1,
  French = 2,
  German = 3,
  Spanish = 4,
  Portuguese = 5,
}

export enum Character {
  Funny = 0,
  Inspirational = 1,
  Official = 2,
  Engaging = 3,
  Educational = 4,
  Friendly = 5,
}

export enum Perspective {
  Impersonal = 0,
  FirstPerson = 1,
  YouSingular = 2,
  We = 3,
  YouPlural = 4,
}

export const LANGUAGES: string[] = ["Italian", "English", "French", "German", "Spanish", "Portuguese"];
export const CHARACTERS: string[] = ["Funny", "Inspirational", "Official", "Engaging", "Educational", "Friendly"];
export const PERSPECTIVES: string[] = ["Impersonal", "First person", "You (singular)", "We", "You (plural)"]
