<div class="container" *ngIf="(theme !== 'attachPin')" id="default">

    <!-- Drag n Drop theme Starts -->
    <div *ngIf="theme == 'dragNDrop'" id="dragNDrop"
         [ngClass]="(hideSelectBtn && hideResetBtn) ? null : 'dragNDropBtmPad'" class="dragNDrop">
        <div style="position:relative;">
            <div id="div1" class="div1 afu-dragndrop-box p-5" (drop)="drop($event)" (dragover)="allowDrop($event)">
                <div class="mb-4"><b>{{ replaceTexts?.dragNDropBox }}</b></div>
                <label for="sel{{id}}" class="btn btn-primary afu-select-btn"
                       [ngClass]="progressBarShow ? 'disabled' : null"
                       *ngIf="!hideSelectBtn">{{ replaceTexts?.selectFileBtn }}</label>
            </div>
            <!-- <span class='label label-info' id="upload-file-info{{id}}">{{allowedFiles[0]?.name}}</span> -->
        </div>
    </div>
    <!-- Drag n Drop theme Ends -->

    <input type="file" id="sel{{id}}" style="display: none" *ngIf="!hideSelectBtn" [disabled]="progressBarShow"
           (change)="onChange($event)" title="Select file" name="files[]" [accept]=formatsAllowed
           [attr.multiple]="multiple ? '' : null"/>
    <button class="btn btn-info btn-sm resetBtn afu-reset-btn" (click)="resetFileUpload()" *ngIf="!hideResetBtn"
            [disabled]="progressBarShow">{{ replaceTexts?.resetBtn }}
    </button>
    <!-- <br *ngIf="!hideSelectBtn"> -->
    <p class="constraints-info afu-constraints-info">{{ formatsAllowedText }}<br />{{ replaceTexts?.sizeLimit }}:
        {{ (convertSize(maxSize)) }}
    </p>

    <p *ngIf="uploadMsg" class="{{uploadMsgClass}} + afu-upload-status">{{ uploadMsgText }}
    <p>
    <div *ngIf="!isAllowedFileSingle && progressBarShow && !hideProgressBar">
        <div class="progress col-xs-4 padMarg afu-progress-bar">
      <span class="progress-bar progress-bar-success" role="progressbar"
            [ngStyle]="{'width':uploadPercent+'%'}">{{ uploadPercent }}%</span>
        </div>
        <br>
        <br>
    </div>
    <button class="btn btn-success afu-upload-btn" type="button" (click)="uploadFiles()"
            [disabled]="!enableUploadBtn && progressBarShow" *ngIf="!autoUpload">{{ replaceTexts?.uploadBtn }}
    </button>
    <br>
</div>
