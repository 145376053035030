import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {NgForm} from '@angular/forms';
import {DialogData} from "../confirm-dialog/confirm-dialog.component";

export interface InputDialogData extends DialogData {
    inputType: string;
    inputPlaceholder: string;
    inputValue: any;
}

@Component({
    selector: 'app-input-box-dialog',
    templateUrl: './input-box-dialog.component.html',
})
export class InputBoxDialogComponent {
    constructor(
        public dialogRef: MatDialogRef<InputBoxDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: InputDialogData
    ) {
    }

    private cancelling: boolean = false;

    onConfirm(form: NgForm): void {
        if (form.valid && !this.cancelling) {
            this.dialogRef.close(this.data.inputValue);
        }
    }

    onCancel(): void {
        this.cancelling = true;
        this.dialogRef.close(null);
    }
}
