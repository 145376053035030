import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
//import {Media, PostProject} from 'src/app/models/models';
import {LibraryService} from 'src/app/services/library.service';
import {getClientFromRoute, openSnackBar} from 'src/app/utils';
import {Client} from "../../../models/client-management";
import {ClientsService} from "../../../services/clients.service";
import {exhaustMap, filter, firstValueFrom, Subject, Subscription, takeUntil, throttleTime} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {ConfirmDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {Media} from "../../../models/media";
import {InputBoxDialogComponent} from "../../../dialogs/input-box-dialog/input-box-dialog.component";
import {PreviewDialogComponent, PreviewDialogData} from "../../../dialogs/preview-dialog/preview-dialog.component";
import {UploadDialogComponent, UploadDialogData} from "../../../dialogs/upload-dialog/upload-dialog.component";
import {CdkScrollable, ScrollDispatcher} from "@angular/cdk/overlay";
import {map} from "rxjs/operators";
import {InViewportAction} from "ng-in-viewport";


interface ViewMedia extends Media {
    isSelected: boolean;
}

@Component({
    selector: 'app-library',
    templateUrl: './media-library.component.html',
    styleUrls: ['./media-library.component.scss']
})
export class LibraryComponent implements OnInit, OnDestroy {

    public medias: ViewMedia[] = [];
    public client: Client | null = null;
    public isBusy: boolean = false;
    public isLoading: boolean = false;
    private lastPage: number = 1;
    private totalItems: number | null = null;

    private pageSize = 20;

    private routeSubscription: Subscription | null = null;

    //@ViewChild(CdkScrollable) scrollable: CdkScrollable | undefined;

    constructor(private clientsService: ClientsService,
                private libraryService: LibraryService,
                public dialog: MatDialog,
                private snackBar: MatSnackBar,
                private router: Router,
                private activatedRoute: ActivatedRoute,
    ) {

    }

    ngOnInit(): void {

        // Monitor route changes
        this.routeSubscription = this.activatedRoute.params.subscribe(params => {
            let clientId = params['clientId'];
            console.log("Client from route subscribe: ", clientId);
            if (clientId) {
                this.clientsService.get(+clientId).then(client => {
                    this.client = client;
                    this.fetchNewItems(true);
                });
            }
        });
    }

    ngOnDestroy() {

        if (this.routeSubscription)
            this.routeSubscription.unsubscribe();
    }

    deselectAll(): void {
        for (let media of this.medias) {
            media.isSelected = false;
        }
    }

    async removeMedia(media: Media) {

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'Confirm Delete',
                message: 'Are you sure you want to delete this media?',
                confirmText: 'Yes',
                cancelText: 'No',
                cancelColor: 'primary',
                confirmColor: 'warn'
            }
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        try {
            await this.libraryService.removeMedia(media.id);
            let itm = this.removeMediaInternal(media.id);
            openSnackBar(this.snackBar, "Media removed", "close");
        } catch (e) {
            console.error(e);
            openSnackBar(this.snackBar, "Failed to remove media", "close");
            return;
        }


    }

    async uploadMedia() {
        console.log("upload media");

        if (this.client == null) {
            console.warn("Client is not supposed to be null here");
            return;
        }

        const data: UploadDialogData = {
            clientId: this.client.id
        };

        const dialogRef = this.dialog.open(UploadDialogComponent, {
            width: '640px',
            data: data
        });

        const needRefresh: boolean = await firstValueFrom(dialogRef.afterClosed());

        if (needRefresh) {
            await this.fetchNewItems(true);
        }
    }


    // TODO: To verify: Potrebbe succedere che l'utente cancelli un numero sufficiente di media da far scattare il fetch di nuovi media. Potrebbe sballare il conteggio delle pagine.
    removeMediaInternal(id: number): MediaRemoveInfo {

        let media: Media | undefined = undefined;

        let idx = this.medias.findIndex(x => x.id === id);
        if (idx !== -1) {
            media = this.medias[idx];
            this.medias.splice(idx, 1);
        }

        return {idx: idx, media: media};

    }

    removeMultipleMediaInternal(ids: number[]): MediaRemoveInfo[] {

        let removedMedia: MediaRemoveInfo[] = [];

        for (let id of ids) {
            let idx = this.medias.findIndex(x => x.id === id);
            if (idx !== -1) {
                let media = this.medias[idx];
                this.medias.splice(idx, 1);
                removedMedia.push({idx: idx, media: media});
            }
        }
        return removedMedia;
    }


    async removeSelected() {

        let selectedMedia = this.medias.filter(x => x.isSelected);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'Confirm Delete',
                message: 'Are you sure you want to delete ' + selectedMedia.length + ' media?',
                confirmText: 'Yes',
                cancelText: 'No',
                cancelColor: 'primary',
                confirmColor: 'warn'
            }
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        this.isBusy = true;

        let cnt = 0;

        try {
            let res = await this.libraryService.removeMultipleMedia(selectedMedia.map(x => x.id));
            this.removeMultipleMediaInternal(res.deletedIds);
            cnt = res.deletedIds.length;
            this.isBusy = false;
            openSnackBar(this.snackBar, cnt + " media removed", "close");
        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Failed to remove media", "close");
        }

    }

    shareMedia(media: Media) {
        // TODO: Add share media
        console.log("share media " + media.id);
    }

    async openMedia(media: Media) {

        const data: PreviewDialogData = {
            previewUrl: media.thumbnailUrl,
            name: media.title,
            width: media.width,
            height: media.height,
            size: media.size,
            mimeType: media.mimeType,
            fileUrl: this.libraryService.getMediaUrl(media)
        };

        const dialogRef = this.dialog.open(PreviewDialogComponent, {
            width: 'auto',
            data: data
        });

        await firstValueFrom(dialogRef.afterClosed());

    }

    async downloadMedia(media: Media, type: string | null = null) {

        console.log("download media " + media.id + " type: " + type);

        try {
            this.isBusy = true;

            if (type !== null)
                await this.libraryService.downloadMediaVariant(media, type);
            else
                await this.libraryService.downloadMedia(media);

            this.isBusy = false;
        } catch (e) {
            this.isBusy = false;
            console.error(e);
            openSnackBar(this.snackBar, "Failed to download media", "close");
        }
    }

    async editTitle(media: Media) {
        console.log("edit title " + media.id);

        const dialogRef = this.dialog.open(InputBoxDialogComponent, {
            width: '350px',
            data: {
                title: 'Edit Media Title',
                message: 'Enter new title',
                confirmText: 'Save',
                cancelText: 'Cancel',
                confirmColor: 'primary',
                cancelColor: 'plain',
                inputValue: media.title,
                inputType: 'text',
                inputPlaceholder: 'Title'
            }
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (result === undefined || result === null || result === media.title)
            return;

        media.title = result;

        await this.libraryService.changeTitle(media.id, result);

        openSnackBar(this.snackBar, "Title changed", "close");

    }

    async downloadBulk() {
        console.log("download bulk");

        if (this.medias.length == 0 || this.isBusy)
            return;

        // Ask for confirmation and tell the user that the process could take some time
        let selectedMediaIds = this.medias.filter(x => x.isSelected).map(x => x.id);

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: {
                title: 'Download Media',
                message: `Are you sure you want to download ${selectedMediaIds.length} media files?<br /> The process could take some time depending on the number of files.`,
                confirmText: 'Yes',
                cancelText: 'No',
                cancelColor: 'plain',
                confirmColor: 'primary'
            }
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        try {
            this.isBusy = true;

            await this.libraryService.downloadBulk(selectedMediaIds);

            this.isBusy = false;
        } catch (e) {
            this.isBusy = false;
            console.error(e);
            openSnackBar(this.snackBar, "Failed to download media", "close");
        }
    }

    getSelectedMedia() {
        return this.medias.filter(x => x.isSelected);
    }

    selectAll() {
        for (let media of this.medias) {
            media.isSelected = true;
        }
    }

    private async fetchNewItems(reset: boolean = false) {

        if (this.client == null) {
            console.warn("Client is not supposed to be null here");
            return;
        }

        if (this.isLoading) return;

        this.isLoading = true;

        console.log(`Fetching new items: reset=${reset} lastPage=${this.lastPage} pageSize=${this.pageSize} totalItems=${this.totalItems}`);

        let selectedMediaIds = this.medias.filter(x => x.isSelected).map(x => x.id);

        if (!reset && this.totalItems !== null && (this.lastPage - 1) * this.pageSize >= this.totalItems) {
            console.log(`No more items to fetch: ${this.lastPage - 1} * ${this.pageSize} >= ${this.totalItems}`);
            this.isLoading = false;
            return;
        }

        let page = reset ? 1 : this.lastPage + 1;

        try {

            const res = await this.libraryService.getAll(this.client.id, {
                page: page,
                pageSize: this.pageSize,
                search: null,
                tags: null
            });

            let newItems = res.items.map(x => {
                return {
                    ...x,
                    isSelected: selectedMediaIds.includes(x.id)
                };
            });

            if (reset) {
                this.medias = newItems;
                this.lastPage = 1;
            } else {
                this.medias = [...this.medias, ...newItems];
                this.lastPage++;
            }

            this.totalItems = res.total;

            this.isLoading = false;
        } catch (e) {
            this.isLoading = false;
            console.error(e);
            openSnackBar(this.snackBar, "Failed to load media", "close");
        }
    }

    async onIntersection($event: InViewportAction) {

        if (!$event.visible)
            return;

        if (this.medias.length == 0)
            await this.fetchNewItems(true);
        else
            await this.fetchNewItems(false);
    }

    isImage(media: Media): boolean {
        return media.mimeType.startsWith("image/") && media.mimeType != "image/gif";
    }
}

interface MediaRemoveInfo {
    idx: number;
    media: Media | undefined;
}
