import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AngularFileUploaderConfig} from "../../components/angular-file-uploader/angular-file-uploader.types";
import {environment} from "../../../environments/environment";
import {HttpResponse} from "@angular/common/http";
import {UploadedMedia} from "../../models/media";
import {Subscription} from "rxjs";

export interface UploadDialogData {
    clientId: number;
}

enum UploadStatus {
    Uploading,
    Uploaded,
    Failed
}

enum ItemType {
    Image,
    Video
}

interface UploadedFile {
    status: UploadStatus;
    guid: string | null;
    name: string;
    url: string;
    type: ItemType;
}

@Component({
    selector: 'app-upload-dialog',
    styleUrls: ['./upload-dialog.component.scss'],
    templateUrl: './upload-dialog.component.html',
})
export class UploadDialogComponent implements OnDestroy, OnInit  {

    resetUpload: boolean = false;
    needRefresh: boolean = false;

    items: UploadedFile[] = [];

    afuConfig: AngularFileUploaderConfig = {
        theme: 'dragNDrop',
        hideProgressBar: false,
        hideResetBtn: true,
        hideSelectBtn: false,
        autoUpload: true,
        maxSize: 200,
        uploadAPI: {
            url: environment.baseUrl + 'clients/' + this.data.clientId + '/upload',
        },
        formatsAllowed: '.jpg, .jpeg, .png, .gif, .3g2, .3gp, .3gpp, .asf, .avi, .dat, .divx, .dv, .f4v, .flv, .gif, .m2ts, .m4v, .mkv, .mod, .mov, .mp4, .mpe, .mpeg, .mpeg4, .mpg, .mts, .nsv, .ogm, .ogv, .qt, .tod, .ts, .vob, .wmv, .webm',
        multiple: true,
        replaceTexts: {
            selectFileBtn: 'Select files',
            resetBtn: 'Reset',
            uploadBtn: 'Upload',
            dragNDropBox: 'Drop files here',
            afterUploadMsg_success: 'Upload complete!',
            afterUploadMsg_error: 'Upload failed!',
            sizeLimit: 'Max size limit',
        }
    };

    docUpload(event: HttpResponse<UploadedMedia>) {
        console.log('ApiResponse -> docUpload -> Event: ', event);
        this.needRefresh = true;

        if (event.status !== 200) {
            this.items.filter(x => x.status === UploadStatus.Uploading).forEach(x => x.status = UploadStatus.Failed);
        } else {

            this.items.filter(x => x.status === UploadStatus.Uploading).forEach(x => {
                    x.status = UploadStatus.Uploaded;
                    x.guid = event.body?.fileName ?? null;
                }
            );
        }

    }

    onFileSelected($event: any) {
        console.log('ApiResponse -> onFileSelected -> Event: ', $event);

        this.items = [];

        const files = ($event.dataTransfer ?? $event.target).files;
        if (files) {
            for (const file of files) {

                let isImage = file.type.match('image');
                let isVideo = file.type.match('video');
                // Only images
                if (!isImage && !isVideo) {
                    continue;
                }

                // Exclude already uploaded files
                if (this.items.find(x => x.name === file.name)) {
                    continue;
                }

                const reader = new FileReader();
                reader.onload = (e: any) => {
                    const itm: UploadedFile = {
                        guid: null,
                        name: file.name,
                        url: e.target.result,
                        status: UploadStatus.Uploading,
                        type: isImage ? ItemType.Image : ItemType.Video
                    };
                    this.items.push(itm);
                };
                reader.readAsDataURL(file);
            }
        }
    }
    //private beforeClosedSubscription: Subscription;
    constructor(
        public dialogRef: MatDialogRef<UploadDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: UploadDialogData
    ) {
        //this.beforeClosedSubscription = this.dialogRef.beforeClosed().subscribe(() => {
        //    this.dialogRef.close(this.needRefresh);
        //});
    }

    ngOnDestroy() {
        //if (this.beforeClosedSubscription) {
        //    this.beforeClosedSubscription.unsubscribe();
        //}
    }


    onCancel(): void {
        this.dialogRef.close(this.needRefresh);
    }

    ngOnInit(): void {
        this.dialogRef.backdropClick().subscribe(() => this.dialogRef.close(this.needRefresh));
    }

}
