<!--<h1 mat-dialog-title>Preview of {{data.name}}</h1>-->

<div mat-dialog-content class="mat-typography text-center pb-0 pt-4" [ngSwitch]="data.mimeType" *ngIf="data.fileUrl">
    <img *ngSwitchCase="data.mimeType.startsWith('image') ? data.mimeType : null" src="{{data.fileUrl}}" alt="Preview" class="img-fluid">
    <video *ngSwitchCase="'video/mp4'" controls class="img-fluid">
        <source src="{{data.fileUrl}}" type="video/mp4">
        Your browser does not support the video tag.
    </video>
    <video *ngSwitchCase="'video/webm'" controls class="img-fluid">
        <source src="{{data.fileUrl}}" type="video/webm">
        Your browser does not support the video tag.
    </video>
    <video *ngSwitchCase="'video/ogg'" controls class="img-fluid">
        <source src="{{data.fileUrl}}" type="video/ogg">
        Your browser does not support the video tag.
    </video>
    <audio *ngSwitchCase="'audio/mpeg'" controls class="img-fluid">
        <source src="{{data.fileUrl}}" type="audio/mpeg">
        Your browser does not support the audio tag.
    </audio>
    <div *ngSwitchDefault class="alert alert-info">
        <p class="mb-0">Preview not available for this file type.</p>
    </div>
</div>
<div mat-dialog-content class="mat-typography text-center pb-0 pt-4" *ngIf="!data.fileUrl">
    <div class="alert alert-info">
        <p class="mb-0">Preview not available for this file type.</p>
    </div>
</div>

<div mat-dialog-actions align="end" class="px-4">
    <div style="flex-grow: 1; text-align: left">
        <span class="me-3"><b>{{data.name}}</b></span>
        <mat-chip class="me-2">{{data.width}}x{{data.height}}</mat-chip>
        <mat-chip class="me-2">{{data.size | humanSize}}</mat-chip>
        <mat-chip class="me-2">{{data.mimeType | mime | uppercase }}</mat-chip>
    </div>
    <button mat-flat-button color="primary" (click)="onCancel()">Close</button>
</div>
