import {Character, Language, Perspective} from "./client-management";

export interface Proposal {
    text: string;
    hashtags: string[] | null;
    type: TextGenerationType;
    length: number;
    lengthType: LengthType;
    language: Language;
    character: Character | null;
    perspective: Perspective | null;
}

export enum TextGenerationType {
    Copy = 0,
    Adv
}

export interface ArchivedTextGeneration {
    guid: string | null;
    name: string;
    url: string | null;
    proposals: Proposal[]
}

export enum Status {
    Idle,
    Loading,
    Success,
    Error
}

export enum LengthType {
  About = 0,
  UpTo = 1,
  AtLeast = 2,
}

export const ADVCALLTOACTIONS: string[] = ["Donate", "Learn More", "Book Now", "Sign Up", "Subscribe", "Buy", "Contact Us", "Get Offer"]
export const LENGTHTYPES: string[] = ["About", "Up to", "At least"]
export const TEXTGENERATIONTYPES: string[] = ["Copy", "Adv"]


/*
export interface PostProject {
    id: number;
    name: string;
    description: string;
    posts: Post[];
}


export interface Post {

    guid: string;
    name: string;
    status: PostStatus;

    createdOn: Date;
    createdBy: string;

    updatedOn: Date | null;
    lastUpdatedBy: string | null;

    publishedOn: Date | null;
    scheduledOn: Date | null;

    baseTarget: TargetInfo;

    targets: PostTargetInfo[];

}


export interface TargetInfo {
    text: string;
    media: Media[]
}

export interface PostTargetInfo {
    id: number;
    name: string;
    type: PostTargetType;
    info: TargetInfo | null;
}

export interface PostTarget {
    id: number;
    name: string;
    type: PostTargetType;
}

export enum PostStatus {
    Draft,
    Published,
    Scheduled
}

export interface Tag {
    id: number;
    name: string;
}
*/
export enum PostTargetType {

    FacebookProfile,
    FacebookPage,
    FacebookGroup,

    InstagramProfile,
    InstagramBusiness,

    LinkedInProfile,
    LinkedInPage,
    LinkedInGroup,

    TwitterProfile,
    TwitterBusiness,
    TwitterGroup,
}


export class DialogResult<T> {
    public readonly data: T | null;
    public readonly action: string;

    constructor(action: string, data: T | null = null) {
        this.data = data;
        this.action = action;
    }
}

export const DialogActionOk = "ok";
export const DialogActionCancel = "cancel";
export const DialogActionDelete = "delete";
export const DialogActionSave = "save";
export const DialogActionEdit = "edit";
export const DialogActionCreate = "create";

export const DialogResultOk = new DialogResult(DialogActionOk);

export const DialogResultCancel = new DialogResult(DialogActionCancel);

export const DialogResultDelete = new DialogResult(DialogActionDelete);


export interface PagedResult<T> {
    items: T[];
    total: number;
    page: number,
    pageSize: number;
}
