import { Component, Input, OnInit } from '@angular/core';
import { PostTargetType } from 'src/app/models/models';

@Component({
  selector: 'app-social-icon',
  templateUrl: './social-icon.component.html',
  styleUrls: ['./social-icon.component.scss']
})
export class SocialIconComponent implements OnInit {


  ngOnInit(): void {
  }

  @Input()
  public type: PostTargetType | null = null;

  @Input()
  public size: string = '24px';


}
