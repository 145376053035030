import { Directive, Output, EventEmitter, HostListener } from '@angular/core';

@Directive({
    selector: '[appScrollBottom]'
})
export class ScrollBottomDirective {
    @Output() scrolledToBottom = new EventEmitter<void>();

    @HostListener('scroll', ['$event'])
    onScroll(event: Event): void {
        const target = event.target as HTMLElement;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            this.scrolledToBottom.emit();
        }
    }
}
