import { Component, Inject, inject } from '@angular/core';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ADVCALLTOACTIONS, LENGTHTYPES, Proposal, TEXTGENERATIONTYPES } from 'src/app/models/models';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {CHARACTERS, LANGUAGES, PERSPECTIVES} from "../../models/client-management";

@Component({
  selector: 'app-generation-info-dialog',
  templateUrl: './generation-info-dialog.component.html',
  styleUrls: ['./generation-info-dialog.component.scss']
})
export class GenerationInfoDialogComponent {

  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  public languages = LANGUAGES;
  public perspectives = PERSPECTIVES;
  public characters = CHARACTERS;
  public advCallToActions = ADVCALLTOACTIONS;
  public lengthTypes = LENGTHTYPES;
  public textGenerationTypes = TEXTGENERATIONTYPES;

  constructor(
    public dialogRef: MatDialogRef<GenerationInfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Proposal,
  ) {

  }

  toHashTag(hashtag: string): string {

    // Make capital case (first letter of each word capitalized)
    // Remove spaces
    // Add # in front

    var words = hashtag.split(" ");
    var result = "";

    for (var i = 0; i < words.length; i++) {
      result += words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    result = result.replace(/\s/g, '');
    return "#" + result;

  }


  onSaveClick(): void {
    this.dialogRef.close(this.data);
  }

  addHashtag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      if (this.data.hashtags == null) {
        this.data.hashtags = [];
      }

      this.data.hashtags.push(this.toHashTag(value));
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  removeHashtag(hashtag: string): void {
    const index = this.data.hashtags!.indexOf(hashtag);

    if (index >= 0) {
      this.data.hashtags!.splice(index, 1);
    }
  }

  editHashtag(hashtag: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.removeHashtag(hashtag);
      return;
    }

    const index = this.data.hashtags!.indexOf(hashtag);
    if (index >= 0) {
      this.data.hashtags![index] = value;
    }
  }
}
