import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {A11yModule} from '@angular/cdk/a11y';

import {CommonModule, NgOptimizedImage} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatDividerModule} from '@angular/material/divider';
import {MatBadgeModule} from '@angular/material/badge';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {DatePipe} from '@angular/common';
import {ImageGenerationComponent} from './sections/content-generation/image-generation/image-generation.component';
import {CopyGenerationComponent} from './sections/content-generation/copy-generation/copy-generation.component';
import {PostGenerationComponent} from './sections/content-generation/post-generation/post-generation.component';
import {CalendarComponent} from './sections/schedule/calendar/calendar.component';
import {ClientManagementComponent} from './sections/management/client-management/client-management.component';
import {UserManagementComponent} from './sections/management/user-management/user-management.component';
import {DashboardComponent} from './sections/general/dashboard/dashboard.component';
import {PerformanceTrackingComponent} from './sections/analytics/performance-tracking/performance-tracking.component';
import {SentimentAnalysisComponent} from './sections/analytics/sentiment-analysis/sentiment-analysis.component';
import {WordCloudComponent} from './sections/analytics/word-cloud/word-cloud.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CopyGenerationItemComponent} from './components/copy-generation-item/copy-generation-item.component';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule, MatLabel} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCardModule} from '@angular/material/card';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipInputEvent, MatChipsModule} from '@angular/material/chips';
import {GenerationInfoDialogComponent} from './dialogs/generation-info-dialog/generation-info-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import {ConfirmDialogComponent} from './dialogs/confirm-dialog/confirm-dialog.component';
import {LibraryComponent} from './sections/general/media-library/media-library.component';
import {StopPropagationModule} from './directives/stop-prop.module';
import {MatRippleModule} from '@angular/material/core';
import {PreventDragDirective} from './directives/prevent-drag.directive';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PostManagerComponent} from './sections/general/post-manager/post-manager.component';
import {TimelineComponent} from './sections/schedule/timeline/timeline.component';
import {SocialIconComponent} from './components/social-icon/social-icon.component';
import {HttpClientModule} from "@angular/common/http";
import {EditClientDialogComponent} from "./dialogs/edit-client-dialog/edit-client-dialog.component";
import {LoadingSpinnerComponent} from "./components/loading-spinner/loading-spinner.component";
import {ProjectsManagementComponent} from "./sections/management/projects-management/projects-management.component";
import {EditProjectDialogComponent} from "./dialogs/edit-project-dialog/edit-project-dialog.component";
import {InputBoxDialogComponent} from "./dialogs/input-box-dialog/input-box-dialog.component";
import {PreviewDialogComponent} from "./dialogs/preview-dialog/preview-dialog.component";
import {UploadDialogComponent} from "./dialogs/upload-dialog/upload-dialog.component";
import {AngularFileUploaderModule} from "./components/angular-file-uploader/angular-file-uploader.module";
import {HumanSizePipe} from "./pipes/human-size.pipe";
import {MimePipe} from "./pipes/mime.pipe";
import {
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    ScrollingModule
} from "@angular/cdk/scrolling";
import {MatCell, MatColumnDef, MatHeaderCell, MatTable} from "@angular/material/table";
import {ScrollBottomDirective} from "./directives/scroll-bottom-directive";
import {CookieService} from "ngx-cookie-service";
import {PreviewGenDialogComponent} from "./dialogs/preview-gen-dialog/preview-gen-dialog.component";
import {SafeHtmlPipe} from "./pipes/safe-html.pipe";
import {InViewportModule} from "ng-in-viewport";

@NgModule({
    declarations: [
        AppComponent,
        LoadingSpinnerComponent,
        ImageGenerationComponent,
        CopyGenerationComponent,
        PostGenerationComponent,
        CalendarComponent,
        SentimentAnalysisComponent,
        WordCloudComponent,
        ClientManagementComponent,
        UserManagementComponent,
        DashboardComponent,
        PerformanceTrackingComponent,
        CopyGenerationItemComponent,
        GenerationInfoDialogComponent,
        ConfirmDialogComponent,
        EditClientDialogComponent,
        LibraryComponent,
        TimelineComponent,
        PostManagerComponent,
        PreventDragDirective,
        SocialIconComponent,
        ProjectsManagementComponent,
        EditProjectDialogComponent,
        InputBoxDialogComponent,
        PreviewDialogComponent,
        PreviewGenDialogComponent,
        UploadDialogComponent,
        HumanSizePipe,
        SafeHtmlPipe,
        MimePipe,
        ScrollBottomDirective
    ],
    imports: [
        CommonModule,
        BrowserModule,
        A11yModule,
        HttpClientModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        FormsModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatGridListModule,
        MatDividerModule,
        MatMenuModule,
        MatBadgeModule,
        MatGridListModule,
        MatFormFieldModule,
        MatIconModule,
        MatSelectModule,
        MatSnackBarModule,
        MatCardModule,
        MatListModule,
        MatInputModule,
        MatChipsModule,
        MatDialogModule,
        MatRippleModule,
        StopPropagationModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatAutocompleteModule,
        NgOptimizedImage,
        AngularFileUploaderModule,
        CdkVirtualScrollViewport,
        CdkFixedSizeVirtualScroll,
        CdkVirtualForOf,
        MatTable,
        MatColumnDef,
        MatHeaderCell,
        MatCell,
        ScrollingModule,
        InViewportModule
    ],
    providers: [CookieService],
    bootstrap: [AppComponent]
})
export class AppModule {
}
