import {Pipe, PipeTransform} from '@angular/core';
import mime from 'mime';


@Pipe({
    name: 'mime'
})
export class MimePipe implements PipeTransform {

    transform(mimeType: string): string {

        const ext = mime.getExtension(mimeType);
        return ext ? ext : 'unknown';
    }

}
