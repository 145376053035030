import { Pipe, PipeTransform, Inject } from '@angular/core';
import { LOCALE_ID } from '@angular/core';

@Pipe({
    name: 'humanSize'
})
export class HumanSizePipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {}

    transform(value: number): string {
        if (value === 0) return '0 B';

        const k = 1024;
        const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(value) / Math.log(k));
        const size = value / Math.pow(k, i);

        // Localized number formatting
        const formattedSize = new Intl.NumberFormat(this.locale, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(size);

        return `${formattedSize} ${sizes[i]}`;
    }
}
