<div id="component-content">
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-xl-9">
                <div class="row" *ngIf="images.length == 0 && !isBusy">
                    <div class="col">
                        <div class="alert alert-primary" role="alert">
                            No images generated yet.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 pb-4" *ngFor="let image of images">
                        <mat-card class="h-100">
                            <mat-card-actions class="justify-content-between">
                                <button mat-icon-button color="warn" (click.stop)="removeImage(image)" matTooltip="Remove from list" [disabled]="image.isBusy">
                                    <mat-icon class="m-0">delete</mat-icon>
                                </button>
                                <a mat-icon-button (click.stop)="downloadImage(image)"
                                   matTooltip="Download" [disabled]="image.isBusy">
                                    <mat-icon class="m-0">download</mat-icon>
                                </a>
                                <button *ngIf="image.libraryId == null" mat-icon-button (click.stop)="addToLibrary(image)"
                                        matTooltip="Add to library" [disabled]="image.isBusy">
                                    <mat-icon class="m-0">save</mat-icon>
                                </button>
                                <button *ngIf="image.libraryId != null" mat-icon-button (click.stop)="removeFromLibrary(image)"
                                        matTooltip="Remove from library" color="warn" [disabled]="image.isBusy">
                                    <mat-icon class="m-0">close</mat-icon>
                                </button>
                            </mat-card-actions>
                            <mat-spinner class="m-auto" *ngIf="image.isBusy"></mat-spinner>
                            <div mat-card-image *ngIf="!image.isBusy" (click.stop)="previewImage(image)" matRipple
                                 style="cursor: pointer; height: 20rem; background-color: #F0F0F0; background-size: contain"
                                 appPreventDrag
                                 [ngStyle]="{'background-image': 'url(' + image.url + ')'}">
                            </div>
                            <mat-card-content style="flex-grow: 1" *ngIf="!image.isBusy">
                                <h3 class="text-center mt-2">{{ image.info }}</h3>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 order-first">
                <mat-form-field id="generate-input" class="w-100">
                    <mat-label class="text-wrap">Describe the image you would like to create</mat-label>
                    <textarea matInput rows="3" [(ngModel)]="prompt" (ngModelChange)="saveSettings()">
                    </textarea>
                    @if (prompt) {
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="prompt=''">
                            <mat-icon>close</mat-icon>
                        </button>
                    }
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Image dimensions</mat-label>
                    <mat-select [formControl]="resolutionsControl" (selectionChange)="saveSettings()">
                        <!--<mat-option></mat-option>-->
                        @for (group of resolutionGroups; track group) {
                            <mat-optgroup [label]="group.name" [disabled]="group.disabled">
                                @for (resolution of group.resolutions; track resolution) {
                                    <mat-option [value]="resolution.id">{{ resolution.description + ' (' + resolution.width + ' x ' + resolution.height + ')' }}</mat-option>
                                }
                            </mat-optgroup>
                        }
                    </mat-select>
                </mat-form-field>
                <mat-checkbox class="me-3" [(ngModel)]="fillDetails" (ngModelChange)="saveSettings()">
                    Fill more details</mat-checkbox>
                <mat-checkbox [(ngModel)]="improveFaces" (ngModelChange)="saveSettings()">Improve faces</mat-checkbox>
                <div class="d-flex align-items-center my-4">
                    <button mat-flat-button color="primary" (click)="generateImages()" [disabled]="isBusy">
                        <mat-icon class="me-2">emoji_objects</mat-icon>
                        Generate
                    </button>
                    <mat-spinner *ngIf="isBusy" diameter="24" class="ms-3"></mat-spinner>
                </div>


            </div>

        </div>
    </div>
</div>
