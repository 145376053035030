import {Injectable} from "@angular/core";
import {firstValueFrom} from "rxjs";
import {Client} from "../models/client-management";
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class ImageGenerationService {
    private baseUrl: string = environment.baseUrl;

    constructor(private http: HttpClient) {
    }

    public generateImages(clientId: number, prompt: string, width: number, height: number,
                          promptEnhance: boolean, faceEnhance: boolean): Promise<ImageGenerationResult[]> {

        try {
            const url = this.baseUrl + 'clients/' + clientId + '/generate-media';
            const data = {
                prompt: prompt,
                width: width,
                height: height,
                promptEnhance: promptEnhance,
                faceEnhance: faceEnhance
            };

            return firstValueFrom(this.http.post<ImageGenerationResult[]>(url, data));
        } catch (e) {
            console.error(e);
            throw e;
        }

    }

}

export interface ImageGenerationResult {
    url: string;
}
