import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CHARACTERS, Language, LANGUAGES, PERSPECTIVES, ViewClient} from "../../models/client-management";
import {deepClone} from "../../utils";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DialogActionDelete,
  DialogActionOk,
  DialogResult,
  DialogResultCancel,
  DialogResultDelete
} from "../../models/models";

@Component({
  selector: 'app-edit-client-dialog',
  templateUrl: './edit-client-dialog.component.html',
})
export class EditClientDialogComponent {

  public clientForm: FormGroup;

  public languages: string[] = LANGUAGES;
  public characters: string[] = CHARACTERS;
  public perspectives: string[] = PERSPECTIVES;

  constructor(
    public dialogRef: MatDialogRef<EditClientDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ViewClient | null,
    private fb: FormBuilder) {

    this.clientForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      website: [''],
      language: [Language.English, Validators.required],
      character: [null],
      perspective: [null],
      emoticons: [null],
    });

    if (data) {
      this.clientForm.patchValue({
        name: data.name,
        description: data.description,
        website: data.website,
        language: data.language,
        character: data.character,
        perspective: data.perspective,
        emoticons: data.emoticons
      });
    }
  }

  onConfirm(): void {
    if (this.clientForm.valid) {
      this.dialogRef.close(new DialogResult(DialogActionOk, deepClone(this.clientForm.value)));
    }
  }

  onCancel(): void {
    this.dialogRef.close(DialogResultCancel);
  }

  onDelete() {
    this.dialogRef.close(DialogResultDelete);
  }
}
