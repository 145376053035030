import { Directive, Output, EventEmitter, Renderer2, OnInit, OnDestroy, ElementRef } from '@angular/core';

@Directive({
  selector: '[click.stop]'
})
export class ClickStopDirective implements OnInit, OnDestroy {
  @Output("click.stop") handler = new EventEmitter<Event>();
  private unsubscriber: any;

  constructor(private renderer: Renderer2, private element: ElementRef) { }

  ngOnInit() {
    this.unsubscriber = this.renderer.listen(this.element.nativeElement, "click", event => {
      event.stopPropagation();
      this.handler.emit(event);
    });
  }

  ngOnDestroy() {
    this.unsubscriber();
  }
}