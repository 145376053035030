import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {User} from "./app/models/users";


export function getBaseUrl() {
    return document.getElementsByTagName('base')[0].href;
}

function goToLogin() {
    window.location.href = environment.loginUrl;
}

fetch(environment.baseUrl + "users/self").then(async response => {

    if (!response.ok) {
        console.error("Failed to get current user: " + response.statusText);
        goToLogin();
        return;
    }

    const content = await response.json() as User | null

    if (content === null) {
        console.error("Failed to get current user: " + response.statusText);
        goToLogin();
        return;
    }

    console.info("Logged in as: " + content.userName);
    startApp();

}).catch(e => {
    console.error("Failed to get current user: " + e);
    goToLogin()
});

function startApp() {
    const providers = [
        {provide: 'BASE_URL', useFactory: getBaseUrl, deps: []}
    ];

    if (environment.production) {
        enableProdMode();
    }

    platformBrowserDynamic(providers).bootstrapModule(AppModule)
        .catch(err => console.error(err));
}
