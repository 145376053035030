import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './sections/general/dashboard/dashboard.component';
import { PerformanceTrackingComponent } from './sections/analytics/performance-tracking/performance-tracking.component';
import { SentimentAnalysisComponent } from './sections/analytics/sentiment-analysis/sentiment-analysis.component';
import { WordCloudComponent } from './sections/analytics/word-cloud/word-cloud.component';
import { CopyGenerationComponent } from './sections/content-generation/copy-generation/copy-generation.component';
import { ImageGenerationComponent } from './sections/content-generation/image-generation/image-generation.component';
import { PostGenerationComponent } from './sections/content-generation/post-generation/post-generation.component';
import { ClientManagementComponent } from './sections/management/client-management/client-management.component';
import { UserManagementComponent } from './sections/management/user-management/user-management.component';
import { CalendarComponent } from './sections/schedule/calendar/calendar.component';
import { LibraryComponent } from './sections/general/media-library/media-library.component';
import { PostManagerComponent } from './sections/general/post-manager/post-manager.component';
import { TimelineComponent } from './sections/schedule/timeline/timeline.component';
import {ProjectsManagementComponent} from "./sections/management/projects-management/projects-management.component";

const routes: Routes = [
  { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'client/:clientId/dashboard', component: DashboardComponent },
  { path: 'client/:clientId/generation/image', component: ImageGenerationComponent },
  { path: 'client/:clientId/generation/copy', component: CopyGenerationComponent },
  { path: 'client/:clientId/generation/post', component: PostGenerationComponent },
  { path: 'client/:clientId/library', component: LibraryComponent },
  { path: 'client/:clientId/post-manager', component: PostManagerComponent },
  { path: 'client/:clientId/schedule/calendar', component: CalendarComponent },
  { path: 'client/:clientId/schedule/timeline', component: TimelineComponent},
  { path: 'client/:clientId/analytics/performance-tracking', component: PerformanceTrackingComponent },
  { path: 'client/:clientId/analytics/sentiment-analysis', component: SentimentAnalysisComponent },
  { path: 'client/:clientId/analytics/word-cloud', component: WordCloudComponent },
  { path: 'client/:clientId/projects', component: ProjectsManagementComponent },
  { path: 'management/clients', component: ClientManagementComponent },
  { path: 'management/users', component: UserManagementComponent },
  { path: '**', redirectTo: '/dashboard' } // Redirect all other paths to the dashboard
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
