import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ClickStopDirective } from "./stop-prop.directive";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        ClickStopDirective
    ],
    exports: [
        ClickStopDirective
    ]
})
export class StopPropagationModule {
}