import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export interface PreviewDialogData {
    previewUrl: string;
    name: string;
    width: number;
    height: number;
    size: number;
    mimeType: string;
    fileUrl: string;
}

@Component({
    selector: 'app-preview-dialog',
    templateUrl: './preview-dialog.component.html',
})
export class PreviewDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PreviewDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PreviewDialogData) {
    }
    onCancel(): void {
        this.dialogRef.close();
    }

}
