<div id="component-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="mb-4"><span [matBadge]="projects.length" matBadgeOverlap="false">Projects</span></h1>
      </div>
    </div>
    <div class="row" *ngIf="projects.length == 0 && !isBusy">
      <div class="col">
        <div class="alert alert-primary" role="alert">
          No projects found.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pb-4 " *ngFor="let project of projects">
        <mat-card class="h-100">
          <mat-card-header>
            <div mat-card-avatar class="me-3 d-flex align-items-center">
                <mat-icon>handyman</mat-icon>
            </div>
            <mat-card-title class="project-name">{{ project.name }}</mat-card-title>
            <div mat-card-avatar class="ms-auto" style="order: 3">
              <button mat-icon-button color="primary" (click)="editProject(project)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-card-header>
          <mat-card-content class="pb-2" style="flex: 1">
            <div class="brand-description pb-3" *ngIf="project.description">
              {{ project.description }}
            </div>
            <div class="brand-description pb-3" *ngIf="!project.description">
              <i>No description</i>
            </div>
          </mat-card-content>
          <mat-card-content class="pb-2">
            <div class="brand-details pb-2">
              <mat-chip-set aria-label="brand-details">
                <mat-chip>{{ languages[project.language] }}</mat-chip>
                <mat-chip *ngIf="project.character != null">{{ characters[project.character] }}</mat-chip>
                <mat-chip *ngIf="project.perspective != null">{{ perspectives[project.perspective] }}</mat-chip>
                <mat-chip *ngIf="project.emoticons != null">{{ project.emoticons ? 'Use emoticons' : "No emoticons" }}
                </mat-chip>
              </mat-chip-set>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>

  <app-loading-spinner [isBusy]="isBusy"></app-loading-spinner>

  <div class="position-absolute" style="bottom: 1rem; right: 1rem">
    <button mat-fab color="primary" (click)="addProject()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
