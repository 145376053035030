<h1 mat-dialog-title>{{ data != null ? "Edit " + data.name : "New client" }}</h1>

<form [formGroup]="clientForm" (ngSubmit)="onConfirm()" class="mat-typography">
  <div mat-dialog-content class="mat-typography">
    <div class="container-fluid">
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Name</mat-label>
            <input matInput type="text" placeholder="Name" formControlName="name" required>
            <mat-error *ngIf="clientForm.get('name')?.invalid && clientForm.get('name')?.touched">
              Name is required.
            </mat-error>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Description</mat-label>
            <textarea matInput rows="4" placeholder="Description" formControlName="description"></textarea>
          </mat-form-field>
          <mat-form-field class="w-100">
            <mat-label>Website</mat-label>
            <input matInput type="text" placeholder="Website" formControlName="website">
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Language</mat-label>
            <mat-select formControlName="language">
              <mat-option *ngFor="let l of languages; index as i" [value]="i">{{ l }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Character</mat-label>
            <mat-select formControlName="character">
              <mat-option [value]="null">Not specified</mat-option>
              <!-- separator -->
              <mat-option disabled>Characters</mat-option>
              <mat-option *ngFor="let c of characters; index as i" [value]="i">{{ c }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Perspective</mat-label>
            <mat-select formControlName="perspective">
              <mat-option [value]="null">Not specified</mat-option>
              <mat-option *ngFor="let p of perspectives; index as i" [value]="i">{{ p }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col">
          <mat-form-field class="w-100">
            <mat-label>Emoticons</mat-label>
            <mat-select formControlName="emoticons">
              <mat-option [value]="null">Not specified</mat-option>
              <mat-option [value]="true">Yes</mat-option>
              <mat-option [value]="false">No</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  <div mat-dialog-actions align="end">
    <button mat-flat-button color="warn" type="button" class="me-auto" (click)="onDelete()" *ngIf="data">
      <mat-icon class="me-0 me-sm-2">delete</mat-icon>
      <span class="d-none d-sm-block">Delete</span></button>
    <button mat-flat-button cdkFocusInitial type="button" color="plain" (click)="onCancel()">
      <mat-icon class="me-0 me-sm-2">close</mat-icon><span class="d-none d-sm-block">Cancel</span></button>
    <button mat-flat-button color="primary" type="submit" [disabled]="clientForm.invalid">
      <mat-icon class="me-0 me-sm-2">save</mat-icon>
        <span class="d-none d-sm-block">{{ data != null ? "Save" : "Create" }}</span>
    </button>
  </div>
</form>
