import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {filter, Observable, Subscription} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ClientsService} from './services/clients.service';
import {environment} from 'src/environments/environment';
import {MatSidenav} from '@angular/material/sidenav';
import {Client} from "./models/client-management";
import {getClientFromRoute, openSnackBar} from "./utils";
import {MatSnackBar} from "@angular/material/snack-bar";
import {User} from "./models/users";
import {UsersService} from "./services/users.service";
import {CookieService} from "ngx-cookie-service";
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    @ViewChild('drawer')
    public drawer: MatSidenav | undefined;
    public isDebug: boolean = !environment.production;
    public name: string = environment.name;
    public isBusy: boolean = false;
    public currentClient: Client | null = null;
    public clients: Client[] = [];
    public allClients: Client[] = [];
    public self: User | null = null

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    public profileUrl: string = environment.profileUrl;
    public logoutUrl: string = environment.logoutUrl;

    private routerSubscription: Subscription | null = null;
    private refreshClientsSubscription: Subscription | null = null;

    constructor(
        private breakpointObserver: BreakpointObserver,
        private clientsService: ClientsService,
        private usersService: UsersService,
        private snackBar: MatSnackBar,
        private cookieService: CookieService,
        private router: Router,
        private route: ActivatedRoute
    ) {

    }

    ngOnDestroy() {
        // Unsubscribe to avoid memory leaks
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe();
        }

        if (this.refreshClientsSubscription)
        {
            this.refreshClientsSubscription.unsubscribe();
        }
    }

    private getRouteParam(param: string): string | null {
        let route = this.route;
        while (route.firstChild) {
            route = route.firstChild;
        }
        return route.snapshot.paramMap.get(param);
    }

    ngOnInit(): void {

        this.routerSubscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            const clientId = this.getRouteParam('clientId');
            console.log('Client ID:', clientId);

            if (clientId) {
                this.clientsService.get(+clientId).then(client => {

                    if (client === this.currentClient)
                        return;

                    if (client == null) {
                        console.warn("Client not found: " + clientId);
                        return;
                    }

                    console.log("Selecting client from route change: " + client.name);

                    this.selectClient(client);
                });
            } else {

            }
        });

        this.refreshClientsSubscription = this.clientsService.refreshSource.subscribe(() => {
            console.log ("Refreshing clients from refresh source");
            this.refreshClients();
        });

        this.usersService.self().then(user => {
            this.self = user;
        }, e => {
            console.error(e);
            openSnackBar(this.snackBar, "Error loading current user", "close");
        });

        this.refreshClients();

    }

    safeClose() {
        // Close only if we are in hand set mode
        if (this.breakpointObserver.isMatched(Breakpoints.Handset)) {
            this.drawer?.close();
        }
    }

    public refreshClients(): void {
        this.isBusy = true;

        console.log("Refreshing clients")

        this.clientsService.getAll().then(clients => {

            clients = clients.sort((a, b) => a.name.localeCompare(b.name));

            if (this.currentClient === null) {
                this.currentClient = clients[0];
                console.log ("Current client is null, setting current client to: " + this.currentClient.name);
                //this.clientsService.setCurrentClient(this.currentClient);
            } else {
                this.currentClient = clients.find(c => c.id === this.currentClient?.id) ?? clients[0];
                console.log ("Current client is not null, setting current client to: " + this.currentClient.name);
            }

            this.allClients = clients;
            // Bind to clients except the current one
            this.clients = clients.filter(c => c.id !== this.currentClient?.id);

            this.isBusy = false;
        }, e => {
            openSnackBar(this.snackBar, "Error loading clients", "Retry", 3000);
            console.error(e);
            this.isBusy = false;
        });
    }

    public async selectClient(client: Client): Promise<void> {

        if (client === this.currentClient)
            return;

        // Get the current url
        let url = this.router.url;

        // Find the old client id in the url
        let oldClientId = this.currentClient?.id;

        if (oldClientId == null) {
            console.error("Old client id is null");
            return;
        }

        let newClientId = client.id;

        // Replace the old client id with the new client id
        url = url.replace(`/${oldClientId.toString()}`, `/${newClientId.toString()}`);

        let segments = url.split('/');

        // Remove the first empty segment
        segments.shift();

        segments[0] = '/' + segments[0];

        let t = this;

        this.currentClient = client;

        // Bind to clients except the current one
        this.clients = this.allClients.filter(c => c.id !== this.currentClient?.id);

        //debugger;

        console.log("Navigating to: " + segments.join('/') + " from " + this.router.url);

        // Navigate to the new url
        this.router.navigate(segments);



        /*if (this.currentClient === null) {
            this.currentClient = this.clients[0];
        }

        this.currentClient = client;
        */


        //this.clients = this.allClients.filter(c => c.id !== this.currentClient?.id);

        //this.clientsService.setCurrentClient(client);

    }

    logout() {
        this.cookieService.delete(environment.authCookieName);
        window.location.href = this.logoutUrl;
    }
}
