<div class="container-fluid">
    <div class="row">
        <div class="col-md-6">
            <mat-card>
                <mat-card-header class="justify-content-between">
                    <mat-card-title><mat-icon class="me-3">perm_data_setting</mat-icon>
                        <div *ngIf="name != null">{{name}}</div>
                        <div *ngIf="name == null">Generate content</div>
                    </mat-card-title>
                    <mat-card-subtitle>Generate content for your social media posts or ads</mat-card-subtitle>
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="removeSelf()">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-card-header>
                <mat-card-content class="container-fluid">
                    <div class="row">
                        <div class="col-md-3" *ngIf="image != null">
                            <img [src]="image" class="w-100 h-auto">
                        </div>
                        <div [ngClass]="{'col-md-9': image != null, 'col': image == null}">
                            <div class="row">
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Keywords / Topic</mat-label>
                                        <input matInput type="text" placeholder="What this post is about"
                                            [(ngModel)]="keywords">
                                        <button *ngIf="keywords" matSuffix mat-icon-button aria-label="Clear"
                                            (click)="keywords=''">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-auto">
                                    <mat-form-field class="w-100">
                                        <mat-label>With emoticons</mat-label>
                                        <mat-select [(value)]="emoticons">
                                            <mat-option [value]="null">Not specified</mat-option>
                                            <mat-option [value]="true">Yes</mat-option>
                                            <mat-option [value]="false">No</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Text Character</mat-label>
                                        <mat-select [(value)]="initialCharacter">
                                            <mat-option [value]="null">Not specified</mat-option>
                                            <mat-option *ngFor="let c of characters; index as i"
                                                [value]="i">{{c}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Perspective</mat-label>
                                        <mat-select [(value)]="initialPerspective">
                                            <mat-option [value]="null">Not specified</mat-option>
                                            <mat-option *ngFor="let p of perspectives; index as i"
                                                [value]="i">{{p}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-auto">
                                    <mat-form-field class="w-100">
                                        <mat-label>Language</mat-label>
                                        <mat-select [(value)]="initialLanguage">
                                            <mat-option *ngFor="let l of languages; index as i"
                                                [value]="i">{{l}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md">
                                    <mat-form-field class="w-100">
                                        <mat-label>Call to action link</mat-label>
                                        <input matInput type="text" placeholder="The call to action link to include"
                                            [(ngModel)]="link">
                                        <button *ngIf="link" matSuffix mat-icon-button aria-label="Clear"
                                            (click)="link=''">
                                            <mat-icon>close</mat-icon>
                                        </button>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-card-content>
                <mat-card-actions class="d-flex flex-wrap">
                    <button mat-flat-button color="accent" class="me-0 me-lg-2 w-100 w-lg-auto"><mat-icon
                            class="me-2">playlist_add</mat-icon>Generate Copy</button>
                    <button mat-flat-button color="accent" class="mt-2 mt-md-0 w-100 w-lg-auto"
                        [matMenuTriggerFor]="menu"><mat-icon class="me-2">playlist_add</mat-icon>Generate Adv</button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let a of advCallToActions; index as i">{{a}}</button>
                    </mat-menu>
                </mat-card-actions>
            </mat-card>
        </div>
        <div class="col-md-6">
            <mat-card class="mt-3 mt-md-0">
                <mat-card-header>
                    <mat-card-title><mat-icon class="me-3">notes</mat-icon>
                        <div>Proposals</div>
                    </mat-card-title>
                    <mat-card-subtitle *ngIf="proposals && proposals.length > 0; else noProposals">Here are some
                        proposals for your social media</mat-card-subtitle>
                    <ng-template #noProposals>
                        <mat-card-subtitle>No proposals have been generated yet</mat-card-subtitle>
                    </ng-template>
                </mat-card-header>
                <mat-card-content>
                    <div *ngFor="let proposal of proposals; index as i" class="proposal-item">
                        <button (click)="openDialog(proposal)" mat-icon-button color="primary">
                            <mat-icon *ngIf="proposal.type == 1" class="me-3">shopping_cart</mat-icon>
                            <mat-icon *ngIf="proposal.type == 0" class="me-3">message</mat-icon>
                        </button>
                        <div class="ms-2" style="flex-grow: 1">
                            <div class="proposal-text">{{proposal.text}}</div>
                            <div *ngIf="proposal.hashtags" class="proposal-hashtag">
                                <span *ngFor="let hashtag of proposal.hashtags">
                                    <mat-chip class="me-2 mt-2">
                                        <a target="_blank"
                                            href="https://www.instagram.com/explore/tags/{{hashtag.substring(1).toLowerCase()}}">
                                            {{hashtag}}</a>
                                    </mat-chip>
                                </span>
                            </div>
                        </div>
                        <button matSuffix mat-icon-button aria-label="Clear" (click)="removeProposal(i)">
                            <mat-icon>close</mat-icon>
                        </button>
                    </div>

                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>