export const environment = {
    production: false,
    name: 'staging',
    baseUrl: 'https://app.contentmatic.digipa.it/api/',
    logoutUrl: 'https://contentmatic.digipa.it/wp-login.php?action=custom_logout',
    loginUrl: 'https://contentmatic.digipa.it/wp-login.php?action=login&redirect_to=https://app.contentmatic.digipa.it',
    profileUrl: 'https://contentmatic.digipa.it/wp-admin/profile.php',
    dashboardUrl: "https://contentmatic.digipa.it/dashboard",
    authCookieName: "ContentMatic.Web"
};
