<div id="component-content">
    <div class="container-fluid">
        <div class="row mb-4">
            <div class="col-md-auto d-flex align-items-center">
                <h2 style="flex-grow: 1"><span matBadgeOverlap="false" matBadge="{{medias.length}}"
                                               matBadgeColor="accent"
                                               matBadgePosition="above after">Media Library</span></h2>
            </div>
        </div>
        <div class="row" *ngIf="medias.length == 0 && !isBusy">
            <div class="col">
                <div class="alert alert-primary" role="alert">
                    No media found.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" *ngFor="let media of medias">
                <mat-card class="h-100" style="overflow: hidden">
                    <mat-card-actions class="justify-content-between">
                        <button mat-icon-button (click)="media.isSelected = !media.isSelected">
                            <mat-icon *ngIf="media.isSelected; else checked" class="m-0">check_box
                            </mat-icon>
                            <ng-template #checked>
                                <mat-icon class="m-0">check_box_outline_blank</mat-icon>
                            </ng-template>
                        </button>
                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                matTooltip="Actions">
                            <mat-icon class="m-0">more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item (click)="downloadMedia(media)" *ngIf="!isImage(media)">
                                <mat-icon>download</mat-icon>
                                <span>Download</span>
                            </button>
                            <button mat-menu-item [matMenuTriggerFor]="dlmenu" *ngIf="isImage(media)">
                                <mat-icon>download</mat-icon>
                                <span>Download as</span>
                            </button>
                            <mat-menu #dlmenu="matMenu">
                                <button mat-menu-item (click)="downloadMedia(media, 'webp')">
                                    <mat-icon>language</mat-icon>
                                    <span>WEBP</span>
                                </button>
                                <mat-divider></mat-divider>
                                <button mat-menu-item (click)="downloadMedia(media, 'jpeg')">
                                    <mat-icon>photo_camera</mat-icon>
                                    <span>JPG</span>
                                </button>
                                <button mat-menu-item (click)="downloadMedia(media, 'png')">
                                    <mat-icon>image</mat-icon>
                                    <span>PNG</span>
                                </button>
                            </mat-menu>
                            <button mat-menu-item (click)="editTitle(media)">
                                <mat-icon>edit</mat-icon>
                                <span>Edit title</span>
                            </button>
                            <button mat-menu-item (click)="shareMedia(media)" disabled>
                                <mat-icon>share</mat-icon>
                                <span>Share</span>
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item (click)="removeMedia(media)">
                                <mat-icon color="warn">delete</mat-icon>
                                <span>Delete</span>
                            </button>
                        </mat-menu>
                    </mat-card-actions>
                    <div mat-card-image
                         style="cursor: pointer; height: 20rem; background-color: #F0F0F0; background-size: contain"
                         appPreventDrag (click)="openMedia(media)" matRipple
                         [ngStyle]="{'background-image': 'url(' + media.thumbnailUrl + ')'}">
                    </div>
                    <mat-card-content style="flex-grow: 1" class="d-flex justify-content-center align-items-center">
                        <h3 class="text-center mt-3">{{ media.title }}</h3>
                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-xxl-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 pb-4" inViewport
                 [inViewportOptions]="{ threshold: [0] }"
                 (inViewportAction)="onIntersection($event)">
                <div class="col d-flex justify-content-center">
                    <mat-spinner *ngIf="isLoading" diameter="64" class="m-4">
                    </mat-spinner>
                </div>
            </div>
        </div>
        <!--<div class="row" id="loading-row" inViewport
             [inViewportOptions]="{ threshold: [0] }"
             (inViewportAction)="onIntersection($event)">
            <div class="col d-flex justify-content-center">
                <mat-spinner *ngIf="isLoading" diameter="64" class="m-4">
                </mat-spinner>
            </div>
        </div>-->
    </div>
    <app-loading-spinner [isBusy]="isBusy"></app-loading-spinner>
</div>

<div id="actions-container" class="pe-4 pb-4">
    <button mat-fab [matMenuTriggerFor]="amenu" class="me-3 d-block d-md-none" *ngIf="getSelectedMedia().length > 0"
            matTooltip="Actions">
        <mat-icon class="m-0">menu</mat-icon>
    </button>
    <mat-menu #amenu="matMenu">
        <button *ngIf="getSelectedMedia().length != medias.length" mat-menu-item  class="me-3" (click)="selectAll()">
            <mat-icon color="plain">check_box_outline</mat-icon>
            <span>Select all {{ medias.length }} items</span>
        </button>
        <button *ngIf="getSelectedMedia().length > 0" mat-menu-item  class="me-3" (click)="deselectAll()">
            <mat-icon color="accent">check_box_outline_blank</mat-icon>
            <span>Deselect all {{ getSelectedMedia().length }} items</span>
        </button>
        <mat-divider></mat-divider>
        <button *ngIf="getSelectedMedia().length > 1" mat-menu-item class="me-3" (click)="downloadBulk()">
            <mat-icon color="plain">download</mat-icon>
            <span>Download selected</span>
        </button>
        <button *ngIf="getSelectedMedia().length > 0" mat-menu-item class="me-3" (click)="removeSelected()">
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete selected</span>
        </button>
    </mat-menu>

    <button *ngIf="getSelectedMedia().length != medias.length" mat-fab color="plain" class="me-3 d-none d-md-block" (click)="selectAll()"
            matTooltip="Select all media"><mat-icon>check_box_outline</mat-icon></button>
    <button *ngIf="getSelectedMedia().length > 0" mat-fab color="accent" class="me-3 d-none d-md-block" (click)="deselectAll()"
            matTooltip="Deselect all media"><mat-icon>check_box_outline_blank</mat-icon></button>
    <button *ngIf="getSelectedMedia().length > 1" mat-fab color="plain" class="me-3 d-none d-md-block" (click)="downloadBulk()"
            matTooltip="Download all selected media"><mat-icon>download</mat-icon></button>
    <button *ngIf="getSelectedMedia().length > 0" mat-fab color="warn" class="me-3 d-none d-md-block" (click)="removeSelected()"
            matTooltip="Delete selected"><mat-icon>delete</mat-icon></button>


    <button mat-fab color="primary" class="me-3" (click)="uploadMedia()"
            matTooltip="Upload"><mat-icon>add</mat-icon>
    </button>


</div>



