import {Inject, Injectable} from '@angular/core';
import {
  Character,
  Client,
  ClientCreate,
  Language,
  Perspective,
  Project,
  ProjectCreate
} from "../models/client-management";

import {HttpClient} from '@angular/common/http';
import {environment} from "../../environments/environment";
import {Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  private baseUrl: string = environment.baseUrl;

  constructor(private http: HttpClient) {
  }

  async getAll(clientId: number): Promise<Project[]> {
    return new Promise<Project[]>((resolve, reject) => {
      this.http.get<Project[]>(this.baseUrl + '/clients/' + clientId + '/projects').subscribe({
        next: (result) => {
          resolve(result);
        },
        error: e => reject(e)
      });
    });
  }

  async get(id: number): Promise<Project | null> {
    return new Promise<Project | null>((resolve, reject) => {
      this.http.get<Project>(this.baseUrl + '/projects/' + id).subscribe({
        next: (result) => {
          resolve(result);
        },
        error: e => reject(e)
      });
    });
  }

  async add(project: ProjectCreate) {
    return new Promise<Project>((resolve, reject) => {
      this.http.post<Project>(this.baseUrl + '/projects', project).subscribe({
        next: (result) => {
          resolve(result);
        },
        error: e => reject(e)
      });
    });
  }

  async edit(project: Project) {
    return new Promise<Project>((resolve, reject) => {
      this.http.put<Project>(this.baseUrl + '/projects/' + project.id, project).subscribe({
        next: (result) => {
          resolve(result);
        },
        error: e => reject(e)
      });
    });
  }

  async remove(id: number, skipTrash: boolean = false) {
    return new Promise<void>((resolve, reject) => {
      this.http.delete<void>(this.baseUrl + '/projects/' + id + '?skipTrash=' + skipTrash).subscribe({
        next: () => {
          resolve();
        },
        error: e => reject(e)
      });
    });
  }
}
