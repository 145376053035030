import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventDrag]'
})
export class PreventDragDirective {

  constructor() { }

  @HostListener('dragstart', ['$event'])
  onDragStart(event: Event) {
    event.preventDefault();
  }
}