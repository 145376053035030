import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, DialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { GenerationInfoDialogComponent } from 'src/app/dialogs/generation-info-dialog/generation-info-dialog.component';
import {ArchivedTextGeneration, LengthType, Proposal, Status} from 'src/app/models/models';
import { deepClone } from 'src/app/utils';
import {Character, Language, Perspective} from "../../models/client-management";

@Component({
  selector: 'app-copy-generation-item',
  templateUrl: './copy-generation-item.component.html',
  styleUrls: ['./copy-generation-item.component.scss']
})
export class CopyGenerationItemComponent implements OnInit, OnChanges {

  constructor(public dialog: MatDialog) { }

  openDialog(gen: Proposal): void {
    const dialogRef = this.dialog.open(GenerationInfoDialogComponent, {
      data: deepClone(gen),
      width: '1500px',
    });

    dialogRef.afterClosed().subscribe(result => {

      if (result) {
        console.log('The dialog was closed with result', result);

        gen.hashtags = result.hashtags;
        gen.text = result.text;

      } else

        console.log('The dialog was closed');

    });
  }

  ngOnInit(): void {
  }

  @Input()
  public name: string | null = null;

  @Input()
  public guid: string | null = null;

  @Input()
  public language: Language | null = null;

  @Input()
  public character: Character | null = null;

  @Input()
  public perspective: Perspective | null = null;

  @Input()
  public image: string | null = null;

  @Input()
  public proposals: Proposal[] = [];

  @Output()
  public onRemove = new EventEmitter();


  ngOnChanges(changes: SimpleChanges): void {
    if (!this.initialLanguage && this.language) {
      this.initialLanguage = this.language;
    }

    if (!this.initialCharacter && this.character) {
      this.initialCharacter = this.character;
    }

    if (!this.initialPerspective && this.perspective) {
      this.initialPerspective = this.perspective;
    }
  }

  public initialLanguage: Language = Language.Italian;
  public initialCharacter: Character = Character.Funny;
  public initialPerspective: Perspective = Perspective.Impersonal;


  public keywords: string | null = null;
  public emoticons: boolean | null = null;
  public link: string | null = null;
  public lengthType: LengthType | null = null;
  public numProposals: number = 3;
  public numHashtags: number = 3;

  public status: Status = Status.Idle;

  languages = ["Italian", "English", "French", "German", "Spanish", "Portuguese"];
  characters = ["Funny", "Inspirational", "Official", "Engaging", "Educational", "Friendly"];
  perspectives = ["Impersonal", "First person", "You (singular)", "We", "You (plural)"]
  advCallToActions = ["Donate", "Learn More", "Book Now", "Sign Up", "Subscribe", "Buy", "Contact Us", "Get Offer"]
  lengthTypes = ["About", "Up to", "At least"]

  public removeSelf() {
    // Send message to remove self
    this.onRemove.emit();
  }

  public removeProposal(index: number) {

    const dialogData: DialogData = {
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this proposal?',
      confirmText: 'Yes',
      cancelText: 'No',
      confirmColor: 'warn',
      cancelColor: 'primary'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        this.proposals.splice(index, 1);
      }
    });

  }

}
