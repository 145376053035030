<h1 mat-dialog-title class="d-flex align-items-center mt-3"><mat-icon class="me-2">edit</mat-icon><div>Edit Proposal</div></h1>
<div mat-dialog-content class="mat-typography">

    <div class="container-fluid">
        <div class="row">
            <div class="col-md">
                <mat-form-field class="w-100">
                    <mat-label>Content</mat-label>
                    <textarea matInput rows="4" placeholder="Content" [(ngModel)]="data.text">
                    </textarea>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-label>Hashtags</mat-label>
                    <mat-chip-grid #chipGrid aria-label="Enter Hashtags">
                        <mat-chip-row *ngFor="let hashtag of data.hashtags" (removed)="removeHashtag(hashtag)"
                            [editable]="true" (edited)="editHashtag(hashtag, $event)"
                            [aria-description]="'press enter to edit ' + hashtag">
                            {{hashtag}}
                            <button matChipRemove [attr.aria-label]="'remove ' + hashtag">
                                <mat-icon>cancel</mat-icon>
                            </button>
                        </mat-chip-row>
                        <input placeholder="New hashtag..." [matChipInputFor]="chipGrid"
                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur"
                            (matChipInputTokenEnd)="addHashtag($event)" />
                    </mat-chip-grid>
                </mat-form-field>
            </div>
            <div class="col-md-3">

                <mat-card>
                    <mat-card-header>
                        <!--<mat-card-subtitle>The settings that were used to generate this proposal</mat-card-subtitle>-->
                        <mat-card-title><mat-icon class="me-2">info</mat-icon>Info</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <span matListItemTitle>{{textGenerationTypes[data.type]}}</span>
                                <span matListItemMeta>Type</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>{{data.character != null ? characters[data.character] : 'Not specified'}}</span>
                                <span matListItemMeta>Character</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>{{languages[data.language]}}</span>
                                <span matListItemMeta>Language</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>{{lengthTypes[data.lengthType]}} {{data.length}}</span>
                                <span matListItemMeta>Length</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span matListItemTitle>{{data.perspective != null ? perspectives[data.perspective] : 'Not specified'}}</span>
                                <span matListItemMeta>Perspective</span>
                            </mat-list-item>
                        </mat-list>
                    </mat-card-content>
                </mat-card>

            </div>

        </div>
    </div>


</div>
<div mat-dialog-actions align="end">
    <button mat-flat-button color="accent" [mat-dialog-close]><mat-icon class="me-2">close</mat-icon>Cancel</button>
    <button mat-flat-button color="primary" (click)="onSaveClick()"><mat-icon class="me-2">save</mat-icon>Save</button>
</div>