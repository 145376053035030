<div style="position: fixed; right: 0; top: 50%; z-index: 999" *ngIf="isDebug">
    <span id="device-xs" class="badge bg-primary d-block d-sm-none">XS</span>
    <span id="device-sm" class="badge bg-primary d-none d-sm-block d-md-none">SM</span>
    <span id="device-md" class="badge bg-primary d-none d-md-block d-lg-none">MD</span>
    <span id="device-lg" class="badge bg-primary d-none d-lg-block d-xl-none">LG</span>
    <span id="device-xl" class="badge bg-primary d-none d-xl-block d-xxl-none">XL</span>
    <span id="device-xxl" class="badge bg-primary d-none d-xxl-block">XXL</span>
</div>
<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar class="py-3 d-flex justify-content-center">
            <svg class="h-100 me-2" id="navbar-logo" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                 viewBox="0 0 194.4 194.4"
                 style="enable-background:new 0 0 194.4 194.4;" xml:space="preserve">
        <style type="text/css">
          .st0 {
              fill-rule: evenodd;
              clip-rule: evenodd
          }

          .st1 {
              fill-rule: evenodd;
              clip-rule: evenodd;
              fill: #FFFFFF;
          }
        </style>
                <g>
          <g id="shape" transform="translate(152.000000, 111.000000)">
            <path id="shape.secondary" class="st0 primary-fill" d="M-54.8-110.7c-47.6,0-86.2,38.5-86.2,86.1v21.5c0,47.6,38.6,86.2,86.2,86.2h86.2V-3.1
                      v-21.5C31.3-72.2-7.2-110.7-54.8-110.7"></path>
              <path class="st1" d="M9.8,48.4h-29.9v-67.6c0-19.1-15.6-34.7-34.7-34.7s-34.7,15.6-34.7,34.7h-16.8c0-28.4,23.1-51.5,51.5-51.5
                      S-3.3-47.6-3.3-19.2v50.9H9.9L9.8,48.4L9.8,48.4z M-40.5-3c0,7.9-6.4,14.4-14.4,14.4C-62.8,11.4-69.3,5-69.3-3
                      c0-7.9,6.4-14.4,14.4-14.4S-40.5-11-40.5-3z"></path>
          </g>
        </g>
      </svg>
            Content Matic
        </mat-toolbar>
        <mat-nav-list>
            <mat-list-item routerLink="/dashboard" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>dashboard</mat-icon>
                <span matListItemTitle>Dashboard</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'post-manager']" routerLinkActive="active"
                           ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>list</mat-icon>
                <span matListItemTitle>Post manager</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'library']" routerLinkActive="active"
                           ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>photo_library</mat-icon>
                <span matListItemTitle>Media library</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Content Generation</div>
            <mat-list-item matListItemMeta [routerLink]="['/client', currentClient?.id, 'generation', 'image']" routerLinkActive="active"
                           (click)="safeClose()"
                           ariaCurrentWhenActive="page">
                <span matListItemTitle>Generate image</span>
                <mat-icon matListItemIcon>image</mat-icon>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'generation', 'copy']" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>notes</mat-icon>
                <span matListItemTitle>Generate copy</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'generation', 'post']" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>library_books</mat-icon>
                <span matListItemTitle>Generate post</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Schedule</div>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'schedule', 'timeline']" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>schedule</mat-icon>
                <span matListItemTitle>Timeline</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'schedule', 'calendar']" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>calendar_today</mat-icon>
                <span matListItemTitle>Calendar</span>
            </mat-list-item>
            <mat-divider></mat-divider>
            <div mat-subheader>Analytics</div>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'analytics', 'performance-tracking']" routerLinkActive="active" (click)="safeClose()"
              ariaCurrentWhenActive="page">
              <mat-icon matListItemIcon>show_chart</mat-icon>
              <span matListItemTitle>Performance tracking</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'analytics', 'sentiment-analysis']" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="safeClose()">
              <mat-icon matListItemIcon>bar_chart</mat-icon>
              <span matListItemTitle>Sentiment analysis</span>
            </mat-list-item>
            <!--<mat-list-item routerLink="/analytics/word-cloud" routerLinkActive="active" ariaCurrentWhenActive="page" (click)="safeClose()">
              <mat-icon matListItemIcon>cloud</mat-icon>
              <span matListItemTitle>Word cloud</span>
            </mat-list-item>-->
            <mat-divider></mat-divider>
            <div mat-subheader>Management</div>
            <mat-list-item routerLink="/management/clients" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>business_center</mat-icon>
                <span matListItemTitle>Clients</span>
            </mat-list-item>
            <mat-list-item routerLink="/management/users" routerLinkActive="active" ariaCurrentWhenActive="page"
              (click)="safeClose()">
              <mat-icon matListItemIcon>group</mat-icon>
              <span matListItemTitle href="">Users</span>
            </mat-list-item>
            <mat-list-item [routerLink]="['/client', currentClient?.id, 'projects']" routerLinkActive="active" ariaCurrentWhenActive="page"
                           (click)="safeClose()">
                <mat-icon matListItemIcon>handyman</mat-icon>
                <span matListItemTitle href="">Projects</span>
            </mat-list-item>
        </mat-nav-list>
        <mat-chip *ngIf="isDebug" class="m-3">
            {{ name }}
        </mat-chip>

    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <!-- Use white color *ngIf="isHandset$ | async" -->
            <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>

            <button class="ms-2" mat-icon-button color="accent" [matMenuTriggerFor]="clientsMenu"
                    *ngIf="clients.length > 0">
                <mat-icon>expand_more</mat-icon>
            </button>
            <!-- <mat-icon class="d-show d-sm-none" matListItemIcon>arrow_forward_ios</mat-icon> -->
            <span *ngIf="clients.length == 0" style="cursor: pointer" class="project-name mx-3 hide-cursor">{{
                    currentClient != null ? currentClient.name : 'Loading...'
                }}</span>
            <span *ngIf="clients.length > 0" style="cursor: pointer" [matMenuTriggerFor]="clientsMenu"
                  class="project-name mx-3 hide-cursor">{{
                    currentClient != null ? currentClient.name : 'Loading...'
                }}</span>
            <mat-menu #clientsMenu="matMenu">
                <!--<div disabled="true" mat-menu-item><b>Choose client</b></div>-->
                <button mat-menu-item *ngFor="let client of clients" (click)="selectClient(client)">
                    <mat-icon>business_center</mat-icon>
                    <span>{{ client.name }}</span>
                </button>
            </mat-menu>
            <span class="menu-spacer"></span>
            <button class="d-none d-sm-block me-3" matBadge="3" matBadgePosition="before" matBadgeSize="small"
                    matBadgeColor="accent" matBadgeOverlap="true" mat-icon-button [matMenuTriggerFor]="notifications"
                    aria-label="Example icon-button with a menu">
                <mat-icon>notifications</mat-icon>
            </button>
            <mat-menu #notifications="matMenu">
                <button mat-menu-item>
                    <mat-icon>info</mat-icon>
                    <span class="notification-text">Ed rhoncus dolor consequat. Fusce ac ullamcorper odio, sed lacinia
            sapien</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>info</mat-icon>
                    <span class="notification-text">Iaculis sodales lorem nec, dapibus semper ex. Aenean et sollicitudin
            elit</span>
                </button>
                <button mat-menu-item>
                    <mat-icon>warning</mat-icon>
                    <span class="notification-text">Duis lobortis consectetur ultrices</span>
                </button>
            </mat-menu>
            <button mat-mini-fab [matMenuTriggerFor]="menu">
                <mat-icon>person</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <div mat-menu-item disabled>
                    <b>{{ self?.userName || 'Loading...' }}</b>
                </div>
                <mat-divider></mat-divider>
                <a mat-menu-item [href]="profileUrl" target="_blank">
                    <mat-icon>account_circle</mat-icon>
                    <span>Profile</span>
                </a>
                <button mat-menu-item disabled>
                    <mat-icon>settings</mat-icon>
                    <span>Settings</span>
                </button>
                <mat-divider></mat-divider>
                <a mat-menu-item (click)="logout()">
                    <mat-icon>exit_to_app</mat-icon>
                    <span>Logout</span>
                </a>
            </mat-menu>
        </mat-toolbar>
        <div id="main-container">
            <router-outlet></router-outlet>
        </div>
        <!-- Add Content Here -->
    </mat-sidenav-content>
</mat-sidenav-container>
