import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ADVCALLTOACTIONS, ArchivedTextGeneration } from 'src/app/models/models';
import { ArchiveService } from 'src/app/services/archive.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent, DialogData } from 'src/app/dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { openSnackBar } from 'src/app/utils';
import {
  Character,
  CHARACTERS,
  Client,
  Language,
  LANGUAGES,
  Perspective,
  PERSPECTIVES
} from "../../../models/client-management";
import {ClientsService} from "../../../services/clients.service";

@Component({
  selector: 'app-copy-generation',
  templateUrl: './copy-generation.component.html',
  styleUrls: ['./copy-generation.component.scss']
})
export class CopyGenerationComponent implements OnInit {

  public client: Client | null = null;

  public settingsLanguage: Language = Language.Italian;
  public settingsCharacter: Character | null = null;
  public settingsPerspective: Perspective | null = null;

  public noMediaLanguage: Language = Language.Italian;
  public noMediaCharacter: Character | null = null;
  public noMediaPerspective: Perspective | null = null;
  public noMediaKeywords: string = "";
  public noMediaEmoticons: boolean | null = null;

  public items: ArchivedTextGeneration[] = [];

  public languages = LANGUAGES;
  public perspectives = PERSPECTIVES;
  public characters = CHARACTERS;
  public advCallToActions = ADVCALLTOACTIONS;

  setClient(client: Client) {
    console.log("Selecting client " + client.name);
    this.client = client;

    this.items = this.archiveService.getArchive(client.id);
    this.settingsLanguage = client.language;
    this.settingsCharacter = client.character;
    this.settingsPerspective = client.perspective;
  }

  constructor(private clientsService: ClientsService,
    private archiveService: ArchiveService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar) {

      /*
    this.clientsService.current$.subscribe(client => {
      this.setClient(client);
    });
    if (this.clientsService.current !== null)
      this.setClient(this.clientsService.current);*/

    this.noMediaLanguage = this.settingsLanguage;
    this.noMediaCharacter = this.settingsCharacter;
    this.noMediaPerspective = this.settingsPerspective;

  }

  saveClientSettings() {
    openSnackBar(this.snackBar, "Client settings saved", "Close");
  }

  addWithoutMedia() {
    let item: ArchivedTextGeneration = {
      guid: null,
      name: "Generate without media",
      proposals: [],
      url: null
    };

    // Add to the beginning
    this.items.unshift(item);

    openSnackBar(this.snackBar, "Item added", "Close");
  }

  removeItem(idx: number) {

    const dialogData: DialogData = {
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this content?',
      confirmText: 'Yes',
      cancelText: 'No',
      cancelColor: 'primary',
      confirmColor: 'warn'
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '350px',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
      if (result) {

        // Save element before actually removing it
        let item = this.items[idx];
        this.items.splice(idx, 1);

        openSnackBar(this.snackBar, "Item removed", "Undo", 3000, () => {
          this.items.splice(idx, 0, item);
        }, () => {
          if (item.guid !== null)
            this.archiveService.removeArchivedTextGeneration(item.guid);
        });
      }
    });

  }

  ngOnInit(): void {
  }

}
