import {Component, OnInit} from '@angular/core';

import {CHARACTERS, Client, ClientCreate, LANGUAGES, PERSPECTIVES, ViewClient} from "../../../models/client-management";
import {EditClientDialogComponent} from "../../../dialogs/edit-client-dialog/edit-client-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {deepClone, equals, openSnackBar} from 'src/app/utils';
import {ClientsService} from "../../../services/clients.service";
import {firstValueFrom} from "rxjs";
import {DialogActionCancel, DialogActionDelete, DialogResult} from "../../../models/models";
import {ConfirmDialogComponent, DialogData} from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import {Router} from "@angular/router";
import {UsersService} from "../../../services/users.service";

@Component({
    selector: 'app-client-management',
    templateUrl: './client-management.component.html',
    styleUrls: ['./client-management.component.scss']
})
export class ClientManagementComponent implements OnInit {

    public languages: string[] = LANGUAGES;
    public characters: string[] = CHARACTERS;
    public perspectives: string[] = PERSPECTIVES;

    public clients: ViewClient[] = [];

    constructor(public dialog: MatDialog, private snackBar: MatSnackBar, private usersService: UsersService,
                private clientsService: ClientsService, private router: Router) {
    }

    public isBusy: boolean = false;

    ngOnInit(): void {

        this.isBusy = true;

        this.clientsService.getAll().then(clients => {
            this.clients = clients.map(c => {
                return {
                    id: c.id,
                    name: c.name,
                    description: c.description,
                    website: c.website,
                    language: c.language,
                    character: c.character,
                    perspective: c.perspective,
                    emoticons: c.emoticons
                }
            });

            this.isBusy = false;

        }, e => {
            console.error(e);
            this.isBusy = false;

            openSnackBar(this.snackBar, "Error loading clients", "Retry", 3000, () => {
                this.ngOnInit();
            });

        });
    }
/*
    async goToProjects(client: ViewClient) {
        console.log("Go to project with client id: " + client.id);

        //this.clientsService.setCurrentClient(client);

        await this.router.navigate(['/management/projects', client.id]);
    }

    async goToMedias(client: ViewClient) {
        console.log("Go to media with client id: " + client.id);

        this.clientsService.setCurrentClient(client);

        await this.router.navigate(['/library']);
    }*/

    async editClient(client: ViewClient) {

        const dialogRef = this.dialog.open(EditClientDialogComponent, {
            width: '40rem',
            data: deepClone(client)
        });

        let dialogResult: DialogResult<Client> = await firstValueFrom(dialogRef.afterClosed());

        if (!dialogResult || dialogResult.action == DialogActionCancel)
            return;

        if (dialogResult.action == DialogActionDelete) {
            await this.deleteClient(client.id);
            this.clientsService.refresh();
            return;
        }

        const result = dialogResult.data;

        // Check if no changes were made
        if (result == null || equals(client, result)) {
            console.log("No changes were made");
            return;
        }

        console.log("Updating client");
        try {

            this.isBusy = true;

            await this.clientsService.edit({
                id: client.id,
                name: result.name,
                description: result.description,
                website: result.website,
                language: result.language,
                character: result.character,
                perspective: result.perspective,
                emoticons: result.emoticons
            });

            openSnackBar(this.snackBar, "Client updated", "close");

            client.name = result.name;
            client.description = result.description;
            client.website = result.website;
            client.language = result.language;
            client.character = result.character;
            client.perspective = result.perspective;
            client.emoticons = result.emoticons;

            this.isBusy = false;

            // broadcast changes to other components
            this.clientsService.refresh();

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error updating client", "Retry", 3000, () => {
                this.editClient(client);
            });
        }
    }

    async deleteClient(clientId: number) {
        console.log("Delete client with id: " + clientId);

        const dialogData: DialogData = {
            title: 'Confirm Delete',
            message: 'Are you sure you want to delete this content?',
            confirmText: 'Yes',
            cancelText: 'No',
            cancelColor: 'primary',
            confirmColor: 'warn'
        };

        const dialogRef = this.dialog.open(ConfirmDialogComponent, {
            width: '350px',
            data: dialogData
        });

        let result = await firstValueFrom(dialogRef.afterClosed());

        if (!result)
            return;

        console.log("Deleting client");

        this.isBusy = true;

        try {

            // TODO: Per ora teniamo in standby la feature del cestino
            await this.clientsService.remove(clientId, true);

            this.clients = this.clients.filter(c => c.id != clientId);

            this.clientsService.refresh();

            this.isBusy = false;
            openSnackBar(this.snackBar, "Client deleted", "close");

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error deleting client", "Retry", 3000, () => {
                this.deleteClient(clientId);
            });
        }

    }

    async addClient() {

        const dialogRef = this.dialog.open(EditClientDialogComponent, {
            width: '40rem',
            data: null
        });

        let dialogResult: DialogResult<Client> = await firstValueFrom(dialogRef.afterClosed());

        if (dialogResult.action == DialogActionCancel)
            return;

        const result = dialogResult.data;

        if (result == null) {
            console.log("No data entered");
            return;
        }

        console.log("Creating client");
        try {

            this.isBusy = true;

            let addUser: ClientCreate = result as ClientCreate;

            const self = await this.usersService.self();

            addUser.userId = self.id;

            const client = await this.clientsService.add(addUser);

            this.clients.push({
                id: client.id,
                name: client.name,
                description: client.description,
                website: client.website,
                language: client.language,
                character: client.character,
                perspective: client.perspective,
                emoticons: client.emoticons
            });

            this.clientsService.refresh();

            openSnackBar(this.snackBar, "Client created", "close");

            this.isBusy = false;

        } catch (e) {
            console.error(e);
            this.isBusy = false;
            openSnackBar(this.snackBar, "Error creating client", "Retry", 3000, () => {
                this.addClient();
            });
        }
    }

}
