<!--<h1 mat-dialog-title>Preview of {{data.name}}</h1>-->

<div mat-dialog-content class="mat-typography text-center pb-0 pt-4">
    <img src="{{data.url}}" alt="Preview" class="img-fluid">
</div>
<div mat-dialog-content class="mat-typography pb-0">
    <mat-form-field class="w-100">
        <mat-label>Prompt</mat-label>
        <textarea rows="4" matInput readonly>{{ data.prompt }}</textarea>
    </mat-form-field>
</div>
<div mat-dialog-actions align="end" class="px-4" style="justify-content: space-between">
    <div>
        <mat-chip class="me-2">{{ data.info }}</mat-chip>
        <mat-chip class="me-2">{{ data.width }}x{{ data.height }}</mat-chip>
        <mat-chip class="me-2" *ngIf="data.fillDetails">Extra details</mat-chip>
        <mat-chip class="me-2" *ngIf="data.improveFaces">Faces improved</mat-chip>
    </div>
    <button mat-flat-button style="place-self: flex-end;" color="primary" (click)="onCancel()">Close</button>
</div>
