import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {
    CHARACTERS,
    EditProjectDialogData,
    Language,
    LANGUAGES,
    PERSPECTIVES,
    ViewProject
} from "../../models/client-management";
import {deepClone, getClientFromRoute} from "../../utils";
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {
    DialogActionDelete,
    DialogActionOk,
    DialogResult,
    DialogResultCancel,
    DialogResultDelete
} from "../../models/models";
import {ClientsService} from "../../services/clients.service";
import {ActivatedRoute} from "@angular/router";

@Component({
    selector: 'app-edit-project-dialog',
    templateUrl: './edit-project-dialog.component.html',
})
export class EditProjectDialogComponent implements OnInit{

    public projectForm: FormGroup;

    public languages: string[] = LANGUAGES;
    public characters: string[] = CHARACTERS;
    public perspectives: string[] = PERSPECTIVES;

    constructor(
        public dialogRef: MatDialogRef<EditProjectDialogComponent>,
        private clientsService: ClientsService,
        @Inject(MAT_DIALOG_DATA) public data: EditProjectDialogData,
        private fb: FormBuilder) {

        let client = data.client;

        this.projectForm = this.fb.group({
            name: ['', Validators.required],
            description: [''],
            website: [''],
            language: [client?.language, Validators.required],
            character: [client?.character],
            perspective: [client?.perspective],
            emoticons: [client?.emoticons],
        });

        if (this.data.project) {
            this.projectForm.patchValue({
                name: this.data.project.name,
                description: this.data.project.description,
                language: this.data.project.language,
                character: this.data.project.character,
                perspective: this.data.project.perspective,
                emoticons: this.data.project.emoticons
            });
        }

    }

    onConfirm(): void {
        if (this.projectForm.valid) {
            this.dialogRef.close(new DialogResult(DialogActionOk, deepClone(this.projectForm.value)));
        }
    }

    onCancel(): void {
        this.dialogRef.close(DialogResultCancel);
    }

    onDelete() {
        this.dialogRef.close(DialogResultDelete);
    }

    ngOnInit(): void {

    }
}
