import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Client} from "../../../models/client-management";
import {ClientsService} from "../../../services/clients.service";
import {getClientFromRoute} from "../../../utils";
import {Subscription} from "rxjs";


@Component({
    selector: 'app-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit, OnDestroy {
    public client: Client | null = null;
    private routeSubscription: Subscription | null = null;

    constructor(private route: ActivatedRoute, private clientsService: ClientsService) {


    }


    ngOnInit(): void {
        // Monitor route changes
        this.routeSubscription = this.route.params.subscribe(params => {
            let clientId = params['clientId'];
            console.log("Client from route subscribe: ", clientId);
            if (clientId) {
                this.clientsService.get(+clientId).then(client => {
                    this.client = client;
                });
            }
        });
    }

    ngOnDestroy() {
        if (this.routeSubscription)
            this.routeSubscription.unsubscribe();
    }

}
