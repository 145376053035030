import { Injectable } from '@angular/core';
import {ArchivedTextGeneration, LengthType, TextGenerationType} from '../models/models';
import {Character, Language, Perspective} from "../models/client-management";

@Injectable({
  providedIn: 'root'
})
export class ArchiveService {

  public getArchivedTextGeneration(guid: string): ArchivedTextGeneration | null {
    return ArchiveService.TEXT_GENERATIONS.find(x => x.guid === guid) || null;
  }

  public getArchive(clientId: number): ArchivedTextGeneration[] {
    return ArchiveService.TEXT_GENERATIONS;
  }

  public removeArchivedTextGeneration(guid: string): void {
    console.log("Calling API to remove archived text generation " + guid);
  }

  public removeProposal(guid: string, proposalIndex: number): void {
    console.log("Calling API to remove proposal " + proposalIndex + " from archived text generation " + guid);
  }

  private static readonly TEXT_GENERATIONS: ArchivedTextGeneration[] = [
    {
      guid: '1',
      name: 'a saint tropez',
      url: '/assets/uploads/1.jpg',
      proposals: [
        {
          text: 'Perfetto connubio: Vino frizzante Chardonnay e cioccolato belga. Gusto indimenticabile.',
          hashtags: ["#VinoCioccolato", "#GustoIndimenticabile", "#PerfettoConnubio"],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: Perspective.Impersonal
        },
        {
          text: 'Serata romantica: Vino, cioccolato e il suono delle onde. Un vero paradiso!',
          hashtags: ["#SerataRomantica", "#Paradiso", "#DolcezzaDelleOnde"],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: Perspective.Impersonal
        },
        {
          text: 'L\'eleganza del cioccolato belga si unisce alla dolcezza del vino francese. Un\'esperienza irresistibile!',
          hashtags: ["#EleganzaDelCioccolato", "#VinoFrancese", "#EsperienzaIrresistibile"],
          type: TextGenerationType.Copy,
          character: Character.Engaging,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: Perspective.Impersonal
        },
        {
          text: 'Che dolcezza! 🍫❤️ Unisci il cioccolato belga con lo Chardonnay frizzante: un\'esperienza deliziosa! 🥂🌊',
          hashtags: ["#chocolatelovers", "#winepairing", "#bottledelights"],
          type: TextGenerationType.Adv,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'Un connubio perfetto: vino francese + cioccolato belga. 😍 Assapora il piacere in una bottiglia di dolcezza! 🍷🍫',
          hashtags: ["#oceanview", "#winetime", "#chocolatedreams "],
          type: TextGenerationType.Adv,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'Brindiamo all\'amore per il cioccolato e al gusto raffinato dello Chardonnay! 🥂💕 Sognando l\'oceano e il piacere in ogni sorso! 🌊🍫',
          hashtags: ["#champagnelife", "#belgianchocolate", "#tastethesplendor "],
          type: TextGenerationType.Adv,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },

      ]
    },
    {
      guid: '2',
      name: 'bolle di sapone',
      url: '/assets/uploads/2.jpg',
      proposals: [
        {
          text: 'Un\'esplosione di gusto: il connubio perfetto tra il Chardonnay e il cioccolato belga in una bottiglia!',
          hashtags: ["#CioccolatoInBottiglia", "#ChardonnayFrizzante", "#DolceSorprendente "],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'L\'incontro più dolce: Chardonnay frizzante e cioccolato belga, un\'esperienza unica da gustare!',
          hashtags: ["#VinoCioccolato", "#UnionePerfetta", "#GustoUnico "],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'L\'eleganza del vino e la dolcezza del cioccolato si incontrano in una bottiglia unica: Chocolate in a bottle!',
          hashtags: ["#VinoElegante", "#DolcezzaPregiata", "#BottigliaUnica "],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },

      ]
    },
    {
      guid: '3',
      name: 'eclissi di luna',
      url: '/assets/uploads/3.jpg',
      proposals: [
        {
          text: 'La dolcezza del cioccolato e l\'eleganza dello Chardonnay frizzante, insieme in una bottiglia.',
          hashtags: ["#chocolatelovers", "#winepairing", "#bottledelights"],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'Due bicchieri di vino, due bottiglie di cioccolato e uno sfondo mozzafiato dell\'oceano.',
          hashtags: ["#oceanview", "#winetime", "#chocolatedreams "],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },
        {
          text: 'Un perfetto connubio: champagne raffinato e cioccolato belga. Scopri il sapore unico.',
          hashtags: ["#champagnelife", "#belgianchocolate", "#tastethesplendor "],
          type: TextGenerationType.Copy,
          character: null,
          language: Language.Italian,
          length: 125,
          lengthType: LengthType.About,
          perspective: null
        },

      ]
    }

  ];

  constructor() { }
}
