import {Injectable} from '@angular/core';
import {Client} from "../models/client-management";
import {DeletedMediaResult, Media} from "../models/media";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {PagedResult} from "../models/models";
import {firstValueFrom} from "rxjs";


export class Filter {
    public page: number = 1;
    public pageSize: number = 20;
    public search: string | null = null;
    public tags: string[] | null = null;
}

@Injectable({
    providedIn: 'root'
})
export class LibraryService {

    private baseUrl: string = environment.baseUrl;

    async removeMedia(id: number): Promise<void> {
        try {
            const url = this.baseUrl + 'media/' + id;
            return firstValueFrom(this.http.delete<void>(url));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async removeMultipleMedia(ids: number[]): Promise<DeletedMediaResult> {
        try {
            const url = this.baseUrl + 'media';
            const data = {ids: ids};
            return firstValueFrom(this.http.delete<DeletedMediaResult>(url, {body: data}));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }


    async getAll(clientId: number, filter: Filter | null = null): Promise<PagedResult<Media>> {
        try {
            let url = this.baseUrl + 'clients/' + clientId + '/media?';

            if (filter) {
                url += 'page=' + filter.page + '&pageSize=' + filter.pageSize;

                if (filter.search) {
                    url += '&search=' + filter.search;
                }

                if (filter.tags) {
                    url += '&tags=' + filter.tags.join(',');
                }

            }

            return firstValueFrom(this.http.get<PagedResult<Media>>(url));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async changeTitle(mediaId: number, newTitle: string): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.put(this.baseUrl + 'media/' + mediaId + "/title", {title: newTitle}).subscribe({
                next: (result) => {
                    resolve();
                },
                error: e => reject(e)
            });
        });
    }

    getMediaUrl(media: Media): string {
        return this.baseUrl + 'media/' + media.id + '/stream';
    }

    async downloadMediaVariant(media: Media, variant: string): Promise<void> {
        const url = this.baseUrl + 'media/' + media.id + '/download-variant/' + variant;

        const blob = await firstValueFrom(this.http.get(url, {responseType: 'blob'}));

        try {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = media.title;
            a.click();
            URL.revokeObjectURL(objectUrl); // Free memory by revoking the object URL
        } catch (e) {
            console.error('Download error:', e);
        }
    }

    async downloadMedia(media: Media): Promise<void> {
        const url = this.baseUrl + 'media/' + media.id + '/download';

        const blob = await firstValueFrom(this.http.get(url, {responseType: 'blob'}));

        try {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = media.title;
            a.click();
            URL.revokeObjectURL(objectUrl); // Free memory by revoking the object URL
        } catch (e) {
            console.error('Download error:', e);
        }
    }

    async downloadBulk(mediaIds: Array<Number>): Promise<void> {
        const url = this.baseUrl + 'media/download-bulk';

        const blob = await firstValueFrom(this.http.post(url, {
            mediaIds: mediaIds
        }, {responseType: 'blob'}));

        try {

            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;

            // The name of the download is media-yyyy_MM_dd-HH_mm_ss.zip

            const now = new Date();

            a.download = 'media-' + now.getFullYear() + '_' + (now.getMonth() + 1) + '_' + now.getDate() + '-' +
                now.getHours() + '_' + now.getMinutes() + '_' + now.getSeconds() + '.zip';

            a.click();
            URL.revokeObjectURL(objectUrl); // Free memory by revoking the object URL

        } catch (e) {
            console.error('Download error:', e);
        }

    }

    constructor(private http: HttpClient) {
    }
}
