import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';


export interface PreviewGenDialogData {
    url: string;
    info: string;
    width: number;
    height: number;
    prompt: string;
    fillDetails: boolean;
    improveFaces: boolean;
}

@Component({
    selector: 'app-preview-gen-dialog',
    templateUrl: './preview-gen-dialog.component.html',
})
export class PreviewGenDialogComponent {

    constructor(
        public dialogRef: MatDialogRef<PreviewGenDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: PreviewGenDialogData) {
    }
    onCancel(): void {
        this.dialogRef.close();
    }

}
