import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-post-generation',
  templateUrl: './post-generation.component.html',
  styleUrls: ['./post-generation.component.scss']
})
export class PostGenerationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
