<div id="component-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="mb-4"><span [matBadge]="clients.length" matBadgeOverlap="false">Clients</span></h1>
      </div>
    </div>
    <div class="row" *ngIf="clients.length == 0 && !isBusy">
      <div class="col">
        <div class="alert alert-primary" role="alert">
          No clients found.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 pb-4" *ngFor="let client of clients">
        <mat-card class="h-100">
          <mat-card-header>
            <div mat-card-avatar class="me-3 d-flex align-items-center">
              <mat-icon>business_center</mat-icon>
            </div>
            <mat-card-title class="d-flex align-items-center">{{ client.name }}</mat-card-title>
            <!--<mat-card-subtitle class="brand-name">{{ client.brandName }}</mat-card-subtitle>-->
            <div mat-card-avatar class="ms-auto me-3" style="order: 2" *ngIf="client.website">
              <a href="{{client.website}}" target="_blank" mat-icon-button>
                <mat-icon>link</mat-icon>
              </a>
            </div>
            <div mat-card-avatar [ngClass]="{'ms-auto': !client.website}" style="order: 3">
              <button mat-icon-button color="primary" (click)="editClient(client)">
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </mat-card-header>
          <mat-card-content class="pb-2" style="flex: 1">
            <div class="brand-description pb-3" *ngIf="client.description">
              {{ client.description }}
            </div>
            <div class="brand-description pb-3" *ngIf="!client.description">
              <i>No description</i>
            </div>
          </mat-card-content>
          <mat-card-content class="pb-2">
            <div class="brand-details pb-2">
              <mat-chip-set aria-label="brand-details">
                <mat-chip>{{ languages[client.language] }}</mat-chip>
                <mat-chip *ngIf="client.character != null">{{ characters[client.character] }}</mat-chip>
                <mat-chip *ngIf="client.perspective != null">{{ perspectives[client.perspective] }}</mat-chip>
                <mat-chip *ngIf="client.emoticons != null">{{ client.emoticons ? 'Use emoticons' : "No emoticons" }}
                </mat-chip>
              </mat-chip-set>
            </div>
          </mat-card-content>
          <mat-divider></mat-divider>
          <mat-card-actions class="d-flex justify-content-center">
            <button mat-flat-button [routerLink]="['/client', client.id, 'projects']">
              <mat-icon class="me-2">handyman</mat-icon>
              Projects
            </button>
            <button mat-flat-button [routerLink]="['/client', client.id, 'library']">
              <mat-icon class="me-2">photo_library</mat-icon>
              Media
            </button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </div>

  <app-loading-spinner [isBusy]="isBusy"></app-loading-spinner>

  <div class="position-absolute" style="bottom: 1rem; right: 1rem">
    <button mat-fab color="primary" (click)="addClient()">
      <mat-icon>add</mat-icon>
    </button>
  </div>
</div>
